<template>
  <div>
    <div class="loading-wrapper" v-if="isLoading || isFetching">
      <preloader />
    </div>
    <template v-if="!isLoading && !isFetching">
      <div class="dashboard" >
        <DashboardSummary />
        <DashboardCollection />
        <DashboardBalances v-if="!showZeroState" class="balances" />
        <template v-else>
          <DashboardZeroStateBalances />
          <DashboardZeroStateAml @enableCollecting="handleEnableCollecting" />
        </template>
      </div>
    </template>
    <dash-board-down-commission v-if="false" @close="closeDownCommission"/>
    <dashboard-create-withdrawal-request-modal-payout />
    <dashboard-swap  @swap-result="openResultModal" />
    <SuccessModal :pid="_uid">
      <template #body>
        {{ $t('swap.modals.success.body-text') }}
      </template>
    </SuccessModal>
    <ErrorModal :pid="_uid" />
    <transition name="slide-fade">
      <DashboardInfo v-if="isInfoOpened" />
    </transition>
    <ChangeAddressBook />
    <two-fa-confirmation :modal-name="'DashboardCreateWithdrawalRequestModalPayout'" />
    <TwoFaRestriction />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import ChangeAddressBook from "../components/modals/addressBook/ChangeAddressBook";
import DashBoardDownCommission from "../components/elements/dashboard/DashBoardDownCommission";
import getUserContent from "../graphQl/queries/getUserContent";
import DashboardSummary from "@/public/components/elements/dashboard/DashboardSummary.vue";
import DashboardCollection from "@/public/components/elements/dashboard/DashboardCollection.vue";
import DashboardBalances from "@/public/components/elements/dashboard/DashboardBalances.vue";
import DashboardInfo from "@/public/components/elements/dashboard/DashboardInfo.vue";
import DashboardZeroStateBalances from "@/public/components/elements/dashboard/DashboardZeroStateBalances.vue";
import DashboardZeroStateAml from "@/public/components/elements/dashboard/DashboardZeroStateAml.vue";
import DashboardCreateWithdrawalRequestModalPayout
  from "@/public/components/modals/dashboard/DashboardCreateWithdrawalRequestModalPayout.vue";
import DashboardSwap from "@/public/components/modals/dashboard/DashboardSwap.vue";
import SwapModal from "@/public/components/modals/swap/index.vue";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";

export default {
  name: 'PaymentsPage',
  title: 'navigation.payments',
  rulesAlias: 'collecting',
    components: {
      TwoFaRestriction,
      TwoFaConfirmation,
      ChangeAddressBook,
      SwapModal,
      DashboardSwap,
      DashboardCreateWithdrawalRequestModalPayout,
      DashboardZeroStateAml,
      DashboardZeroStateBalances,
      DashboardInfo,
      DashboardBalances,
      DashboardCollection,
      DashboardSummary,
      DashBoardDownCommission,
      Preloader,
      SuccessModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Success" */ '/public/components/modals/common/results/Success'),
      ErrorModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Error" */ '/public/components/modals/common/results/Error'),
    },
    data () {
        return {
            isDashboardOpen: 0,
            isFetching: false, // false
        };
    },
    async created() {
      const isShowAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_dashboard
      if (!isShowAllowed) {
        this.$Message.error(this.$t('rules.not_allowed'));
        this.$router.push('/withdrawal-fees');
        return
      }
      this.isFetching = true
        await this.getDashboard(this.currentInterval);
      this.isFetching = false
    },
    computed: {
        ...mapState('user', {
            userId: state => state.user.id,
            userOtpEnabled: state => state.user.otpToken,
        }),
        ...mapState('app', ['isLoading', 'isInit']),
        ...mapState('dashboard', ['isInfoOpened', 'currentInterval']),
      ...mapGetters('app', ['getSelectedOrganization']),
      ...mapGetters('organizations', ['userRoleRules']),
      showZeroState () {
        return !this.getSelectedOrganization.nextCollectingAt
      },
        completed_withdrawal_requests() {
            return this.$t('dashboard.completed_withdrawal_requests');
        },
        executed_orders() {
            return this.$t('dashboard.executed_orders');
        },
    },
    methods: {
        ...mapActions('dashboard', [
            'fetchDashboard',
            'getDashboard',
            'enableCollecting'
        ]),
        ...mapActions('app', [
            'init',
            'openModal',
            'request',
        ]),
      ...mapMutations('dashboard', ['setDashboardInfoState', 'setDashboardInfoPayload', 'fetchOrganizations']),
      openResultModal(result) {
        const { openModal, _uid } = this

        const modalName = !!result?.success ? `BaseSuccessModal_${_uid}` : `BaseErrorModal_${_uid}`

        this.$nextTick(async () => {
          openModal({
            name: modalName,
          });
          await this.getDashboard();
        });
      },
      async handleEnableCollecting ({ aml }) {
          this.isFetching = true
          await this.enableCollecting({ aml });
          await this.init();
          await this.getDashboard();
          this.isFetching = false
      },
      async checkDownCommissionState () {
            const storageState = sessionStorage.getItem('downCommission');
            if (storageState === null) {
                const {data: {getUserContent: {code, result}}} =  await this.request({
                    type: 'query',
                    gql: getUserContent,
                });
                if (code === 0) {
                    const formattedBoolean = Number(result.showCommissionWindow);
                    this.isDashboardOpen = formattedBoolean;
                    sessionStorage.setItem('downCommission', formattedBoolean);
                    return;
                }
            }
            this.isDashboardOpen = Number(storageState);
        },
        closeDownCommission () {
            this.isDashboardOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  height: calc(100vh - 108px);
  display: flex;
  @media screen and (max-width: 576px) {
    height: calc(100vh - 114px);
  }
}
.dashboard {
  padding: 0 30px 30px;
  width: 100%;
  font-family: $Gilroy;
  display: grid;
  grid-template-areas:
      'summary collect'
      'balances aml';
  grid-template-columns: 1fr minmax(370px, 440px);
  grid-gap: 30px;
  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr minmax(370px, 440px);
    grid-gap: 20px;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'summary'
      'collect'
      'balances'
      'aml';
    padding: 0 5px 5px;
  }
}
.balances {
  grid-column-start: balances;
  grid-column-end: aml;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
