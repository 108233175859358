<template>
  <div>
    <div class="business-wallets">
<!--      <div class="business-wallets__title">-->
<!--        {{ $t('business-wallet.title') }}-->
<!--        <common-info-modal :description="$t('info_modals.business_wallet.description')" />-->
<!--      </div>-->
      <zero-state
          v-if="showZeroState"
          :meta="zeroStateMeta"
          :background-transparent="false"
          @open-create-business-address-modal="openAddBusinessAddressModal"
      />
      <Container v-else :overflow="true" class="business-wallets__container">
        <header-first-line-web
          :search.sync="filters.search"
          :hide-zero-balances="hideZeroBalances"
          :hide-archived="hideArchived"
          :filters="filters"
          @refresh="getList"
        />
        <header-second-line-web
          :hide-zero-balances.sync="hideZeroBalances"
          :hide-archived.sync="hideArchived"
          :currency-value.sync="filters.currencyValue"
          :networks-value.sync="filters.networksValue"
          :risk-value.sync="filters.riskLevel"
        />
        <header-first-line-mobile
            :filters.sync="filters"
            :hide-zero-balances="hideZeroBalances"
            :hide-archived="hideArchived"
            @refresh="getList"
        />
        <header-second-line-mobile
            :hide-zero-balances.sync="hideZeroBalances"
            :hide-archived.sync="hideArchived"
        />
        <EmptyTable v-if="!paginateSortedBalances.length"/>
        <div
          v-if="isAddressesLoading"
          class="loader-block"
          style="min-height: 200px"
        >
          <new-spinner />
        </div>
        <template v-else>
          <BusinessWalletAddressesTableWeb
            class="addresses-table-web"
            :list="paginateSortedBalances"
            @refresh="getList"
          />
          <BusinessWalletAddressesListMobile
            class="addresses-list-mobile"
            :list="paginateSortedBalances"
            @refresh="getList"
          />
        </template>
        <pagination
          size="small"
          :key="payoutBalancesCount"
          v-if="payoutBalancesCount > this.pageSize"
          :total="payoutBalancesCount"
          :page-size="pageSize"
          :current="page"
          @page-change="catchPagination"
        />
      </Container>
    </div>

    <InfoBusinessAddress />
    <BusinessCreateWithdrawalRequestModalPayout />
    <ChangeAddressBook/>
    <NewAddressModal/>
    <NotEnoughAdvanceBalance />
    <TwoFaRestriction />
    <TwoFaConfirmation modal-name="BusinessCreateWithdrawalRequestModalPayout"/>
    <AddBusinessAddress />
  </div>
</template>

<script>
import Container from '../components/common/Container';

import preloader from '../components/common/preloader';
import CopyString from "../components/elements/CopyString";
import CurrencyImg from "../components/elements/CurrencyImg";
import btn from '../components/common/btn';
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import EmptyTable from "../components/elements/EmptyTable";
import BtnRefresh from "../components/common/btn-refresh";
import AddBusinessAddress from "../components/modals/business-wallet/AddBusinessAddress";
import InfoBusinessAddress from "../components/modals/business-wallet/InfoBusinessAddress";
import BusinessCreateWithdrawalRequestModalPayout
    from "../components/modals/business-wallet/BusinessCreateWithdrawalRequestModalPayout";
import ChangeAddressBook from "../components/modals/addressBook/ChangeAddressBook";
import NewAddressModal from "../components/modals/addressBook/NewAddressModal";
import NotEnoughAdvanceBalance from "../components/modals/business-wallet/NotEnoughAdvanceBalance";

import CommonInfoModal from "/public/components/modals/commonInfo";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation";
import { isAlbertCrypto } from "@/config";

export default {
    name: "BusinessWallet",
    data() {
        return {
            pageSize: 10,
            page: 1,
            isAddressesLoading: false,
            filters: undefined,
            hideZeroBalances: false,
            hideArchived: true
        };
    },
    rulesAlias: 'wallets_business',
    components: {
      HeaderFirstLineWeb: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Web/FirstLine" */ '/public/components/elements/business-walet/MainBlocks/Header/Web/FirstLine'),
      HeaderSecondLineWeb: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Web/SecondLine" */ '/public/components/elements/business-walet/MainBlocks/Header/Web/SecondLine'),
      HeaderFirstLineMobile: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Mobile/FirstLine" */ '/public/components/elements/business-walet/MainBlocks/Header/Mobile/FirstLine'),
      HeaderSecondLineMobile: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Header/Mobile/SecondLine" */ '/public/components/elements/business-walet/MainBlocks/Header/Mobile/SecondLine'),
      Pagination: () => import(/* webpackChunkName: "public/components/common/uiKit/Pagination.vue" */ '/public/components/common/uiKit/Pagination.vue'),
      BusinessWalletAddressesTableWeb: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Body/Web/Table" */ '/public/components/elements/business-walet/MainBlocks/Body/Web/Table'),
      BusinessWalletAddressesListMobile: () => import(/* webpackChunkName: "public/components/elements/business-walet/MainBlocks/Body/Mobile/List" */ '/public/components/elements/business-walet/MainBlocks/Body/Mobile/List'),
      NewSpinner: () => import (/* webpackChunkName: "/public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),

      TwoFaConfirmation,
      TwoFaRestriction,
      NotEnoughAdvanceBalance,
      NewAddressModal,
      ChangeAddressBook,
      BusinessCreateWithdrawalRequestModalPayout,
      InfoBusinessAddress,
      BtnRefresh,
      EmptyTable,
      Container,
      CopyString,
      CurrencyImg,
      preloader,
      btn,
      CommonInfoModal,
      ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState'),
      AddBusinessAddress: () => import(/* webpackChunkName: "public/components/modals/business-wallet/AddBusinessAddress" */ '/public/components/modals/business-wallet/AddBusinessAddress')
    },
    created() {
        this.resetFilters()
        this.getList()

        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isShowAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_wallets
        if (!isAllowed || !isShowAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/withdrawal-fees');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    methods: {
        ...mapActions('balances', ['getAccountBalancesBusiness', 'getAccountBalancesGroupedBusiness']),
        ...mapActions('app', ['openModal']),
        resetFilters() {
          this.page = 1
          this.filters = {
            search: undefined,
            networksValue: null,
            currencyValue: null,
          }
        },
        getList () {
            const { accountId, selectedOrganizationId: organizationId } = this

            this.isAddressesLoading = true

            this.getAccountBalancesBusiness({ organizationId, accountId, includeArchived: true }).then((data) => {
              this.isAddressesLoading = false
              this.resetFilters()
            })
        },
        openCreateWithdrawalRequestModal(addressId) {
            const {
                openModal,
                accounts,
            } = this;

          if (!this.userOtpEnabled) {
            openModal({
              name: 'TwoFaRestriction',
            });
            return;
          }

            if (Number(accounts[0].balance) < 0) {
                openModal({
                    name: 'NotEnoughAdvanceBalance',
                    payload: {
                        advanceBalanceAmount: accounts[0].balance,
                    },
                });
                return;
            }

            openModal({
                name: 'BusinessCreateWithdrawalRequestModalPayout',
                payload: {
                    addressId,
                },
            });
        },
        catchPagination(page) {
            this.page = page;
        },
        openAddBusinessAddressModal() {
          if (!this.isCreateBusinessAddressAllowed) {
            return
          }
          this.openModal({
            name: 'AddBusinessAddress',
          });
        },
        handleSelectNetwork(value) {
            this.filters.networksValue = value;
            this.filters.currencyValue = null;
        },
        handleSelectCurrency(value) {
            this.filters.currencyValue = value;
        },
      handleSelectRisk(value) {
        this.filters.riskValue = value;
      },

        openInfoBusinessModal(businessAddress) {
            const {
                openModal,
            } = this;

            openModal({
                name: 'InfoBusinessAddress',
                payload: {
                    businessAddress,
                },
            });
        },
    },
    computed: {
        ...mapState('balances', ['balancesBusiness', 'lastUpdateAddress']),
        ...mapState('payoutBalances', ['payoutBalances', 'registriesData']),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('balances', {
            balancesGroupedBusiness: (state) => state.balancesGroupedBusiness.currencyGroups,
        }),
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ...mapState('accounts', ['accounts']),
        ...mapState('app', [
            'networks',
            'currencies',
            'selectedOrganizationId',
        ]),
        ...mapState('user', {
          userOtpEnabled: state => state.user.otpToken,
        }),
        showZeroState({ balancesBusiness }) {
          return !balancesBusiness.length
        },
        isAddressBookAllowed() {
          return this.userRoleRules['address_book'].isAllowed;
        },
        isCreateBusinessAddressAllowed() {
          return this.userRoleRules['wallets_business'].entities.create_business_address;
        },
        accountId({ accounts }) {
            return accounts.find(({ accountId }) => !!accountId).accountId;
        },
        businessBalanceFiltered() {
            this.page = 1;

            const {
                balancesBusiness,
                hideZeroBalances,
                hideArchived,
                filters
            } = this;

            let preparedBalances = _.cloneDeep(balancesBusiness);

            if (hideZeroBalances || hideArchived) {
              if (hideZeroBalances) {
                preparedBalances = preparedBalances.filter(({ balance }) => {
                  return Number(balance) > 0
                })
              }
              if (hideArchived) {
                preparedBalances = preparedBalances.filter(({ isArchived }) => {
                  return !isArchived
                })
              }
            }

            if (filters.search) {
              preparedBalances = preparedBalances.filter(({ alias, address }) => [alias, address].some(item => !!item && item.includes(filters.search)))
            }

            if (filters.networksValue) {
              preparedBalances = preparedBalances.filter(({ networkId }) => networkId === filters.networksValue.id)
            }

            if (filters.currencyValue) {
              preparedBalances = preparedBalances.filter(({ currencyId }) => currencyId === filters.currencyValue.id)
            }

            if (filters.riskLevel) {
              preparedBalances = preparedBalances.filter(({ riskLevel }) => riskLevel === filters.riskLevel.value)
            }

            return preparedBalances;
        },
        paginateSortedBalances() {
            return this.businessBalanceFiltered.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
        payoutBalancesCount() {
            return this.businessBalanceFiltered?.length ?? 0;
        },
        zeroStateMeta({ isCreateBusinessAddressAllowed }) {
          const meta = {
            title: this.$t('zero-states.business-wallet.title'),
            subtitle: this.$t('zero-states.business-wallet.subtitle'),
            notifications: [
              this.$t('zero-states.business-wallet.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.business-wallet.actions.api-integration.title'),
                info: this.$t('zero-states.business-wallet.actions.api-integration.info'),
                btnName: this.$t('zero-states.business-wallet.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/03addresses/post_business-address'
              }
            ],
            type: 'default'
          }

          if (isCreateBusinessAddressAllowed) {
            meta.actions.unshift({
              title: this.$t('zero-states.business-wallet.actions.create-wallet.title'),
              info: this.$t('zero-states.business-wallet.actions.create-wallet.info'),
              btnName: this.$t('zero-states.business-wallet.actions.create-wallet.btn-name'),
              action: 'open-create-business-address-modal'
            })
          }

          return meta
        }
    },
};
</script>

<style lang="scss">
.business-wallets {
  width: 100%;
  padding: 0 30px 30px 30px;

  font-family: var(--new-front-font-family);
  //
  //.business-wallets__header__first-line-web, .business-wallets__header__second-line-web {
  //  display: flex;
  //}
  .business-wallets__header__first-line-mobile, .business-wallets__header__second-line-mobile {
    display: none;
  }
  @include below_tablet() {
    .business-wallets__header__first-line-web, .business-wallets__header__second-line-web {
      display: none;
    }
    .business-wallets__header__first-line-mobile, .business-wallets__header__second-line-mobile {
      display: flex;
    }
  }

  &__title {
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-primary);

    display: flex;
    align-items: center;

    @include below_phone() {
      font-size: $h3;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    padding: 30px;
    gap: 32px;

    width: 100%;

    background: var(--new-front-bg-secondary)!important;

    .loader-block {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .addresses-table-web {
      display: block;
    }
    .addresses-list-mobile {
      display: none;
    }

    @include below_tablet() {
      padding: 15px;

      .addresses-table-web {
        display: none;
      }
      .addresses-list-mobile {
        display: block;
      }
    }
  }
}
</style>
