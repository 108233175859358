<template>
<div>
  <div class="bills">
    <div class="bills__header">
    </div>
    <zero-state
      v-if="showZeroState"
      :meta="zeroStateMeta"
      :background-transparent="false"
      @open-create-invoice-modal="openNewOrderModal"
    />
    <Container v-else class="bills__container">
      <InvoicesTopBar
        class="bills__top-bar"
        :page="page"
        :sortValue="sortValue"
        @is-empty-filters="isEmptyFilters = $event"
        @changePage="changePage"
      />

      <div class="bills__list">
        <table-header
          class="bills__headerTable"
          :list="headerList"
          :key="lang"
          @sort="sortTable"
        />
        <template v-if="hasInvoices && !isLoading">
          <PreviewInvoice
            v-for="(invoice, key) in sortedInvoices"
            :key="key"
            name="invoice"
            class="bills__order"
            :item="invoice"
            :status-enum="statusesEnum"
            :order-enum="$options.invoiceEnum"
            :mobile-status-text-color="mobileStatusTextColor(invoice.status, $options.invoiceEnum)"
            @openInvoice="openInvoice"
          />
        </template>
        <div v-if="!hasInvoices && !isLoading" class="bills__table-empty">
            {{ $t('orphan.empty') }}
        </div>
      </div>
      <preloader v-if="isLoading || !isInit"/>
      <div class="bills__pagination">
        <pagination :size="'small'"
          :total="totalInvoicesCount"
          :page-size="invoicesCountOnPage"
          :current="page"
          v-if="hasInvoices && !isLoading"
          @page-change="changePage"/>
      </div>
    </Container>
    <NewInvoiceModal  @openInvoice="openInvoice"  @updateInvoices="updateInvoices" />
      <transition name="slide-fade">
          <InvoiceInfo v-if="isInfoOpened" :status-enum="statusesEnum"/>
      </transition>
  </div>

</div>
</template>

<script>

  import {mapState, mapActions, mapGetters} from 'vuex';

import InvoicesTopBar from "/public/components/elements/bills/InvoicesTopBar";
import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import NewInvoiceModal from "/public/components/modals/invoices/NewInvoiceModal";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";
import {INVOICE_STATUS_ENUM} from "../../common/constants/invoices-status-enum";
import PreviewInvoice from "../components/elements/bills/PreviewInvoice";
import InvoicesListHeader from "../components/elements/bills/InvoicesListHeader";

import CommonInfoModal from "/public/components/modals/commonInfo";
  import Container from "@/public/components/common/uiKit/Container.vue";
  import TableHeader from "@/public/components/common/uiKit/table/TableHeader.vue";
  import InvoiceInfo from "@/public/components/elements/invoice/InvoiceInfo.vue";
  import _ from "lodash";
  import { isAlbertCrypto } from "@/config";

export default {
    name: 'BillsPage',
    invoiceEnum: INVOICE_STATUS_ENUM,
    title: 'invoice.title',
    rulesAlias: 'invoices',
    components: {
        InvoiceInfo,
        TableHeader,
        Container,
        InvoicesListHeader,
        PreviewInvoice,
        Preloader,
        InvoicesTopBar,
        AtPagination,
        EmptyTable,
        NewInvoiceModal,
        Pagination,
        CommonInfoModal,
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data () {
      return {
          page: 1,
          sortValue: null,
          isEmptyFilters: false
      }
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapState('invoices', [
            'invoicesCountOnPage',
            'invoices',
            'totalInvoicesCount',
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('accounts', [
            'accounts',
        ]),
        ...mapState('invoices', ['isInfoOpened']),
        ...mapState('user', ['lang']),
        accountId () {
            if (this.accounts.length) {
                return this.accounts[0].accountId
            }
            return null
        },
        sortedInvoices () {
            return this.invoices
        },
        headerList () {
            return [
                {
                    title: 'id',
                    slug: 'id',
                    isSort: false
                },
                {
                    title: this.$t('orders.date_of_creation'),
                    slug: 'date',
                    isSort: true
                },
                {
                    title: this.$t('invoice.account_number'),
                    slug: 'number',
                    isSort: false
                },
                {
                    title: this.$t('invoice.invoice_currency'),
                    slug: 'invoice_currency',
                    isSort: false
                },
                {
                    title: this.$t('invoice.account_amount'),
                    slug: 'amount',
                    isSort: false
                },
                {
                    title: this.$t('invoice.exchange_rate'),
                    slug: 'rate',
                    isSort: false
                },
                {
                    title: this.$t('invoice.payment_currency'),
                    slug: 'payment_currency',
                    isSort: false
                },
                {
                    title: this.$t('invoice.payment_network'),
                    slug: 'payment_network',
                    isSort: false
                },
                {
                    title: this.$t('invoice.order_amount'),
                    slug: 'payment_amount',
                    isSort: false
                },
                {
                    title: this.$t('invoice.payment_amount'),
                    slug: 'payment_amount',
                    isSort: false
                },
                {
                    title: this.$t('invoice.order'),
                    slug: 'order',
                    isSort: false
                },
                {
                    title: this.$t('invoice.status'),
                    slug: 'status',
                    isSort: false
                },

            ]
        },
        statusesEnum() {

            const statuses = {
                [INVOICE_STATUS_ENUM.CREATED]: {
                    title: 'invoice.created',
                    colorTag: 'created',
                },
                [INVOICE_STATUS_ENUM.REJECTED]: {
                    title: 'invoice.rejected',
                    colorTag: 'error',
                },
                [INVOICE_STATUS_ENUM.INIT]: {
                    title: 'invoice.active',
                    colorTag: 'active',
                },
                [INVOICE_STATUS_ENUM.PENDING]: {
                    title: 'invoice.in_processing',
                    colorTag: 'in_processing',
                },
                [INVOICE_STATUS_ENUM.PROCESSED]: {
                    title: 'invoice.completed',
                    colorTag: 'completed',
                },
                [INVOICE_STATUS_ENUM.ERROR]: {
                    title: 'invoice.error',
                    colorTag: 'error',
                },
                [INVOICE_STATUS_ENUM.PARTIAL]: {
                    title: 'invoice.partial_payment',
                    colorTag: 'partial_payment',
                },
                [INVOICE_STATUS_ENUM.EXPIRED]: {
                    title: 'invoice.expired',
                    colorTag: 'expired',
                },
                [INVOICE_STATUS_ENUM.OVERPAID]: {
                    title: 'invoice.overpaid',
                    colorTag: 'overpaid',
                },
            };
            return statuses;
        },

        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        hasInvoices() {
            return Boolean(this.invoices.length);
        },
        showZeroState({ isEmptyFilters, hasInvoices }) {
          return isEmptyFilters && !hasInvoices
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.invoices.title'),
            subtitle: this.$t('zero-states.invoices.subtitle'),
            actions: [
              {
                title: this.$t('zero-states.invoices.actions.create-invoice.title'),
                info: this.$t('zero-states.invoices.actions.create-invoice.info'),
                btnName: this.$t('zero-states.invoices.actions.create-invoice.btn-name'),
                action: 'open-create-invoice-modal'
              },
              {
                title: this.$t('zero-states.invoices.actions.api-integration.title'),
                info: this.$t('zero-states.invoices.actions.api-integration.info'),
                btnName: this.$t('zero-states.invoices.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/07invoices/post_make-invoice'
              }
            ],
            type: 'default'
          }
        }
    },
    mounted() {
        this.updateInvoices()
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_invoices;
        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },

    methods: {
        ...mapActions('app', [
          'openModal',
        ]),
        ...mapActions('invoices', [
            'fetchInvoices',
            'fetchInvoice'
        ]),
        openNewOrderModal() {
          this.openModal({
            name: 'NewOrderModal',
          });
        },
        updateInvoices () {
            const {
                $route,
                isInit,
                fetchInvoices,
                page,
                accountId,
            } = this;

            if (isInit) {
                fetchInvoices({
                    route: $route,
                    showUrlFilters: false,
                    page,
                    accountId,
                });
            }
        },
        async openInvoice (invoiceId) {
            const {
                fetchInvoice,
            } = this;

            await fetchInvoice({
                invoiceId,
            })
                .then(order => {
                    this.invoice = order;
                });
        },
        sortTable (slug, direction) {
            this.sortValue = direction ? "ASC" : "DESC"
        },
        mobileStatusTextColor(status, invoiceEnum) {
            switch (status) {
                case invoiceEnum.CREATED:
                    return '#6190e8';
                case invoiceEnum.REJECTED:
                    return '#EB5757';
                case invoiceEnum.INIT:
                    return '#6190e8';
                case invoiceEnum.PENDING:
                    return '#6190e8';
                case invoiceEnum.PROCESSED:
                    return '#13ce66';
                case invoiceEnum.ERROR:
                    return '#EB5757';
                case invoiceEnum.REFUND:
                    return '#ffc82c';
                case invoiceEnum.EXPIRED:
                    return '#8F949A';
                case invoiceEnum.PARTIAL:
                    return '#ffc82c';
                default:
                    return '#8F949A';
            }
        },
        changePage(pageNumber) {
            this.page = pageNumber
        },
    },
    watch: {
        isInit() {
            const {
                $route,
                fetchInvoices,
            } = this;

            fetchInvoices({
                route: $route,
                showUrlFilters: false,
            });
        },
    },
};

</script>

<style lang="scss" scoped>
.bills__table-empty {
  font-weight: 500;
  padding: 30px;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  color: var(--new-front-text-primary-bg-modal-primary);
}
.bills  {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;
  &__container {
    //box-shadow: var(--shadow-container);
    //border-radius: 20px;
    //background: var(--secondary-color);
    //overflow: hidden;
    //margin-top: var(--margin-container-top);
    //margin-bottom: var(--margin-container-bottom);
  }

  &__order {
    background: var(--new-front-main-bg);
  }

  &__list {
    overflow-x: scroll;
    border-radius: 0 0 20px 20px;
  }
  &__header {
    @media screen and (max-width: 950px) {
      text-align: center;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p30;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
    @media screen and (max-width: 950px) {
      padding: 0 15px;
    }
  }

  &__top-bar {
    margin-bottom: $p30;
  }
}

@media screen and (max-width: 1300px) {

}
::v-deep {
  .header {
    //background: transparent;
    grid-template-columns: 20% 20% 20% 20% 20% 25% 20% 25% 20% 20% 20% 20%;
    @media screen and (max-width: 1600px) {
      grid-template-columns: 20% 20% 20% 20% 20% 30% 30% 35% 20% 20% 20% 20%;
    }
    padding: 0;
    //padding-left: 40px;
    gap: 0;
    &__item {
      background: var(--new-front-select-item-bg-active);
      min-height: 56px;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 40px;
      }
      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
@media (max-width: 950px) {
  .bills {
    padding: 0 $p10 $p10 $p10;
    &__headerTable {
      display: none;
    }
    &__top-bar {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__title {
      font-size: $h3;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .common-info-modal-wrapper .common-info-body {
  text-align: left;
}
</style>
