<template>
  <div>
    <div
      v-if="!isSwapHistoryRoute"
      class="swap"
    >
      <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :backgroundTransparent="false"
      />
      <template v-else>
        <div class="swap__title-wrapper">
          <!--        <div class="swap__title">-->
          <!--          {{ $t('swap.title') }}-->
          <!--          <common-info-modal-->
          <!--            :description="$t('info_modals.swap.description')"-->
          <!--          />-->
          <!--        </div>-->
          <history-button
              class="history-btn"
              @click="showHistoryRoute"
          />
        </div>
        <div class="swap__container">
          <div class="swap-dashboard">
            <div class="line mobile-history-line">
              <history-button
                class="history-btn"
                :disabled="!isHistoryAllowed"
                @click="showHistoryRoute"
              />
            </div>
            <div class="line currency-and-network-line">
              <currency-and-network-select-from
                v-model="currencyAndNetworkFrom"
                :list="currenciesWithChainFrom"
                :balances-summary="balancesSummaryFrom"
                :label="$t('swap.currency-network-select.from.label')"
                class="currency-and-network-select"
              />
              <history-button
                class="history-btn"
                :disabled="!isHistoryAllowed"
                @click="showHistoryRoute"
              />
            </div>
            <div class="line">
              <address-select-from
                  v-model="addressFrom"
                  :disabled="!currencyAndNetworkFrom"
                  :wallets-list="walletsListFrom"
                  :balances-summary="balancesSummaryFrom"
                  :currency-and-network="currencyAndNetworkFrom"
                  :label="$t('swap.address_select.label_from')"
                  hide-nullish-balances
                  hide-nullish-addresses
                  class="item"
              />
              <max-amount-input
                  v-model="amountFromModel"
                  class="amount-input item"
                  :label="$t('swap.amount_input.label')"
                  placeholder="0"
                  has-action-btn
                  :min="amountLimits.min"
                  :max="availableBalance > amountLimits.max ? amountLimits.max : availableBalance"
                  @action-btn-click="setAmountFromMax"
              >
                <template #label-left>
                  {{ $t('swap.amount_input.label') }}
                </template>
                <template #label-right>
                  <div class="available-balance">
                  <span class="info">
                    {{ $t('swap.amount_input.available') }}:
                  </span>
                    <span class="value">
                    {{ availableBalanceString }}
                  </span>
                  </div>
                </template>
                <template #bottom-label>
                  <div class="limits-wrapper">
                    <div
                        class="minimum-amount"
                        :class="{ 'error': !isValid.form.minAmount }"
                    >
                    <span class="info">
                      {{ $t('swap.amount_input.minimum') }}:
                    </span>
                      <span class="value">
                      {{ amountLimits.min || '~' }} {{ currencyFrom || 'USD' }}
                    </span>
                    </div>
                    <div
                        class="maximum-amount"
                        :class="{ 'error': !isValid.form.maxAmount }"
                    >
                    <span class="info">
                      {{ $t('swap.amount_input.maximum') }}:
                    </span>
                      <span class="value">
                      {{ amountLimits.max || '~' }} {{ currencyFrom || 'USD' }}
                    </span>
                    </div>
                  </div>
                  <div class="available-balance">
                  <span class="info">
                    {{ $t('swap.amount_input.available') }}:
                  </span>
                    <span class="value">
                    {{ availableBalanceString }}
                  </span>
                  </div>
                </template>
              </max-amount-input>
            </div>
          </div>

          <div class="swap-divider">
            <swap-rate
              :currency-from="currencyAndNetworkFrom?.currency"
              :currency-to="currencyAndNetworkTo?.currency"
              :rate="swapRate"
            />
          </div>

          <div
              class="swap-dashboard"
              style="padding-bottom: 50px"
          >
            <div class="line">
              <currency-and-network-select-to
                  v-model="currencyAndNetworkTo"
                  :list="currenciesWithChainTo"
                  :balances-summary="balancesSummaryTo"
                  :label="$t('swap.currency-network-select.to.label')"
                  class="item"
              >
                <template #minimum>
                  <span />
                </template>
                <template #maximum>
                  <span />
                </template>
              </currency-and-network-select-to>
            </div>
            <div class="line">
              <address-select-to
                  v-model="addressTo"
                  :disabled="!currencyAndNetworkFrom || !networkTo"
                  :wallets-list="walletsListTo"
                  :balances-summary="balancesSummaryTo"
                  :currency-and-network="currencyAndNetworkTo"
                  :account-id="accountId"
                  :label="$t('swap.address_select.label_to')"
                  :show-nullish-balances-for="['business', 'payout']"
                  :hidden-address="addressFrom"
                  hide-nullish-balances
                  class="item"
                  :retry="getBalancesSummary"
              />

              <base-input
                  v-model="amountToModel"
                  class="amount-input item"
                  placeholder="0"
                  :label="$t('swap.amount_to_input.label')"
                  :min="amountLimits.min"
                  :max="availableBalance > amountLimits.max ? amountLimits.max : availableBalance"
              />
            </div>
          </div>

          <div class="footer">
            <div class="info">
              <div
                  class="service"
                  :class="{ 'balance-error': advanceBalanceError }"
              >
                <div class="item">
                  <div class="label">
                    {{ $t('swap.footer.commission_source.service') }}:
                  </div>
                  <div class="value">
                  <span v-if="!advanceBalanceError" class="sublabel">
                    {{ $t('swap.footer.advance_balance') }}
                  </span>
                    <span v-else class="sublabel">
                    {{ $t('bridge.errors.advance_balance_enough') }}
                  </span>
                    <span style="margin-left: 7px; white-space: nowrap">
                    {{ advanceBalance }} USD
                  </span>
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    {{ $t('swap.footer.service_commission') }}:
                  </div>
                  <div class="value">
                    {{ serviceCommission ? prettyAmountWithHideZeros(serviceCommission, 3) : '~' }} USD
                  </div>
                </div>
              </div>
              <div class="network">
                <div class="item">
                  <div class="label">
                    {{ $t('swap.footer.commission_source.network') }}:
                  </div>
                  <div class="value">
                    {{ $t('swap.footer.swap_amount') }}
                  </div>
                </div>
                <div class="item">
                <span class="label">
                  {{ $t('swap.footer.network_commission') }}:
                </span>
                  <span class="value">
                  {{ networksCommission ? prettyAmountWithHideZeros(networksCommission, 3) : '~' }} USD
                </span>
                </div>
              </div>
            </div>
            <div class="action">
              <base-button
                  v-if="!hideActionBtn"
                  type="primary"
                  :label="$t('swap.footer.execute_btn')"
                  class="execute-btn"
                  @click="openSwapModal"
                  :disabled="!isValid.value || !isSwapAllowed"
              />
            </div>
          </div>
        </div>
      </template>


<!--      modals-->
      <SwapModal
        :fee-token="feeToken"
        :commission-loading="commissionLoading"
        :timer="timer"
        @swap-result="openResultModal"
      />
      <SuccessModal :pid="_uid">
        <template #body>
          {{ $t('swap.modals.success.body-text') }}
        </template>
      </SuccessModal>
      <ErrorModal :pid="_uid" />
    </div>
    <RouterView name="history"/>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import SwapModal from '/public/components/modals/swap';
import CommonInfoModal from "/public/components/modals/commonInfo";

import cloneDeep from "lodash/cloneDeep";
import { debounce } from "lodash"

import {
    getPrettyAddress,
    prettyAmountWithHideZeros
} from "/public/filters";
import { isAlbertCrypto } from "@/config";

export default {
    name: "MainSwapPage",
    title: 'navigation.swap',
    components: {
        SwapModal,
        CommonInfoModal,

        AddressSelectFrom:  () => import(/* webpackChunkName: "/public/components/elements/cross-chain/selects/AddressSelect" */ '/public/components/elements/cross-chain/selects/AddressSelect'),
        AddressSelectTo:  () => import(/* webpackChunkName: "/public/components/elements/cross-chain/selects/AddressSelect" */ '/public/components/elements/cross-chain/selects/AddressSelect'),
        BaseSelect: () => import(/* webpackChunkName: "public/components/common/uiKit/selects/base" */ '/public/components/common/uiKit/selects/base'),
        BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
        HistoryButton: () => import(/* webpackChunkName: "public/components/common/uiKit/button/history" */ '/public/components/common/uiKit/button/history'),
        MaxAmountInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/MaxInput" */ '/public/components/common/uiKit/input/MaxInput'),
        BaseInput: () => import(/* webpackChunkName: "public/components/common/uiKit/input/base" */ '/public/components/common/uiKit/input/base'),
        NetworkImg: () => import(/* webpackChunkName: "public/components/common/uiKit/networkImg" */ '/public/components/common/uiKit/networkImg'),
        SuccessModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Success" */ '/public/components/modals/common/results/Success'),
        ErrorModal: () => import(/* webpackChunkName: "public/components/modals/common/results/Error" */ '/public/components/modals/common/results/Error'),

        CurrencyAndNetworkSelectFrom: () => import(/* webpackChunkName: "public/components/elements/cross-chain/selects/CurrencyAndNetwork" */ '/public/components/elements/cross-chain/selects/CurrencyAndNetwork'),
        CurrencyAndNetworkSelectTo: () => import(/* webpackChunkName: "public/components/elements/cross-chain/selects/CurrencyAndNetwork" */ '/public/components/elements/cross-chain/selects/CurrencyAndNetwork'),
        SwapRate: () => import(/* webpackChunkName: "public/components/elements/swap/Rate" */ '/public/components/elements/swap/Rate'),
        ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    rulesAlias: 'wallets_crosschain_swap',
    data() {
        return {
            amountFrom: undefined,
            amountTo: undefined,
            addressFrom: undefined,
            currencyAndNetworkFrom: undefined,
            currencyAndNetworkTo: undefined,
            addressTo: undefined,
            amountLimitsUSD: {
                min: undefined,
                max: undefined,
            },
            hideActionBtn: false,
            balancesSummaryFrom: {
              business: [],
              payin: [],
              payout: [],
              personal: [],
              recurrent: []
            },
            balancesSummaryTo: {},

            timer: undefined,
            tokenTimerInterval: undefined,
            commissionLoading: false,
            feeToken: {},
            swapRate: undefined,
            activeAmountFrom: true,
            activeAmountTo: false,
            swapsList: {}
        };
    },
    watch: {
        isValid: {
          handler({ forFeeToken }) {

            if (!!forFeeToken) {
              if (!this.tokenTimerInterval) {
                this.debounceOnAmount()
              }
            } else {
              this.swapRate = undefined
              this.feeToken = {}

              clearInterval(this.tokenTimerInterval)
              this.tokenTimerInterval = undefined
            }
          },
          deep: true
        },
        currencyAndNetworkFrom() {
            this.currencyAndNetworkTo = undefined;
            this.addressFrom = undefined;
            this.addressTo = undefined;
            this.amountFrom = 0
            this.amountTo = 0

            this.activeAmountFrom = true
            this.activeAmountTo = false
        },
        currencyAndNetworkTo() {
            this.amountTo = 0
            this.activeAmountFrom = true
            this.activeAmountTo = false
        },
        addressFrom() {
            this.addressTo =  undefined;
            this.amountFrom = undefined;
            this.amountTo = undefined;
        },
        advanceBalanceError: {
            handler(v) {
                this.hideActionBtn = v;
            },
            immediate: true,
        }
    },
    computed: {
        ...mapState({
            themeStatus: state => state.themeStatus,
        }),
        ...mapState('app', [
            'selectedOrganizationId'
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('accounts', ['accounts']),
        ...mapState('balances', ['balancesGrouped', 'balancesGroupedPayin', 'balancesGroupedBusiness']),
        ...mapState('app', ['currencies']),
        isHistoryAllowed () {
            return this.userRoleRules[this.$options.rulesAlias].entities.view_crosschain_swaps;
        },
        isSwapAllowed () {
            return this.userRoleRules[this.$options.rulesAlias].entities.make_crosschain_swap;
        },
        debounceOnAmount: ({ setRecallForToken }) => debounce(setRecallForToken, 200),
        activeExchangeSource({ list }) {
            return list.find(({ active }) => !!active);
        },
        allowedRoles({ userRoleRules }) {
          //собираем в массив имена допустимых кошельков из 5-ти возможных в мостах
          const roles = [
            {
              role_name: 'wallets_business',
              id: 'business'
            },
            {
              role_name: 'wallets_payin',
              id: 'payin'
            },
            {
              role_name: 'payout_balances',
              id: 'payout'
            },
            {
              role_name: 'personal',
              id: 'personal'
            },
            {
              role_name: 'recurrent',
              id: 'recurrent'
            }
          ]

          return roles.reduce((acc, role) => {
            if (userRoleRules[role.role_name]?.isAllowed) {
              acc.push(role.id)
            }
            return acc
          }, [])
        },
        walletsListFull() {
          return [
            {
              id: 'business',
              label: this.$t('common-labels.wallets.business_wallets')
            },
            {
              id: 'payin',
              label: this.$t('common-labels.wallets.pay_in')
            },
            {
              id: 'payout',
              label: this.$t('common-labels.wallets.pay_out')
            },
            {
              id: 'personal',
              label: this.$t('common-labels.wallets.personal_address')
            },
            {
              id: 'recurrent',
              label: this.$t('common-labels.wallets.payment_addresses')
            }
          ]
        },
        walletsListTo({ walletsListFrom }) {
          // адреса вывода не могут быть персональными
          return walletsListFrom.filter(({ id }) => id !== 'personal')
        },
        walletsListFrom({ allowedRoles, walletsListFull }) {
          return walletsListFull.filter(wallet => {
            return allowedRoles.includes(wallet.id)
          })
        },
        amountLimits({ amountLimitsUSD, currencyAndNetworkFrom = {} }) {
          const { currency } = currencyAndNetworkFrom

          if (!currency) return {}

          const { usdRate } = currency

          return {
            min: prettyAmountWithHideZeros(amountLimitsUSD.min * 1.02/usdRate, 8),
            max: prettyAmountWithHideZeros(amountLimitsUSD.max * 1.02/usdRate, 8)
          }
        },
        accountId({ accounts }) {
            return accounts.find(({ accountId }) => !!accountId).accountId;
        },
        addressesList({ currencyAndNetworkFrom }) {
            return currencyAndNetworkFrom ? currencyAndNetworkFrom.addressesWithBalances : [];
        },
        advanceBalance({ accounts }) {
            return (!!accounts && accounts[0]) ? Number(accounts[0].balance) : 0;
        },
        advanceBalanceError({ advanceBalance, commonCommission }) {
            return advanceBalance < commonCommission;
        },
        amountFromModel: {
            get({ amountFrom }) {
                return amountFrom;
            },
            set(v) {
                if (v === this.amountFrom) return

                this.amountFrom = v

                const { isValid: { forFeeToken }} = this

                if ( forFeeToken ) {
                  this.debounceOnAmount()
                }

                this.activeAmountFrom = true
                this.activeAmountTo = false
            }
        },
        amountToModel: {
            get({ amountTo }) {
                return amountTo;
            },
            set(v) {
                if (v === this.amountTo) return

                this.amountTo = v

                const { isValid: { forFeeToken }} = this

                if ( forFeeToken ) {
                  this.debounceOnAmount()
                }

                this.activeAmountFrom = false
                this.activeAmountTo = true
            }
        },
        availableBalance({ addressFrom }) {
            return addressFrom && addressFrom.balance;
        },
        availableBalanceString({ currencyAndNetworkFrom, availableBalance }) {
            if ( currencyAndNetworkFrom ) {
                const { currency } = currencyAndNetworkFrom.currency;
                if (availableBalance) {
                    return `${availableBalance} ${currency}`;
                } else {
                    return `~ ${currency}`;
                }
            } else {
                return "~";
            }
        },
        currenciesWithChainFrom({ currencies }) {
           return currencies.reduce(( acc, curr ) => {
              if (curr.networks) {
                const { allowWithdraw } = curr

                if (allowWithdraw) {
                  curr.networks.forEach(network => {
                    const { allowCrosschainSwaps, allowCrosschainSwapFrom } = network

                    if ( allowCrosschainSwaps && allowCrosschainSwapFrom )  {
                      const currencyWithNetwork = {
                        id: `${curr.id}_${network.id}`,
                        currency: curr,
                        network: network,
                      }

                      acc.push(currencyWithNetwork)
                    }
                  })
                }
              }

              return acc
            }, [])
        },
        currenciesWithChainTo({ currencies, currencyAndNetworkFrom }) {
          return currencies.reduce(( acc, curr ) => {
            if (curr.networks) {
              const { allowDeposit, id: currId } = curr
              const { currency: currencyFrom } = currencyAndNetworkFrom || {}
              const { id: currencyFromId } = currencyFrom || {}

              if ( allowDeposit && currencyFromId !== currId ) {
                curr.networks.forEach(network => {
                  const { allowCrosschainSwaps, allowCrosschainSwapTo } = network

                  if ( allowCrosschainSwaps && allowCrosschainSwapTo )  {
                    const currencyWithNetwork = {
                      id: `${curr.id}_${network.id}`,
                      currency: curr,
                      network: network,
                    }

                    acc.push(currencyWithNetwork)
                  }
                })
              }
            }

            return acc
          }, [])
        },
        currencyFrom({ currencyAndNetworkFrom }) {
          return currencyAndNetworkFrom ? currencyAndNetworkFrom?.currency.currency : undefined;
        },
        currencyTo({ currencyAndNetworkTo }) {
            return currencyAndNetworkTo ? currencyAndNetworkTo?.currency.currency : undefined;
        },
        darkTheme({ themeStatus }) {
            return themeStatus;
        },
        networksToList({ currencyAndNetworkFrom = {} }) {
        //получение списка сетей для вывода на основании выбранной валюты
            const { currency: currentCurrency } = currencyAndNetworkFrom;
            const { network: currentNetwork } = currencyAndNetworkFrom;

            if (currentCurrency && currentNetwork) {
                return currentCurrency.networks.filter(network => !!network.allowCrosschainTransfer && network.id !== currentNetwork.id);
            } else {
                return [];
            }
        },
        isSwapHistoryRoute() {
            return this.$route.name === 'swapHistory';
        },
        swapInternalTariff({ accounts, accountId }) {
            const currentAccount = accounts.find( ({ accountId: id }) => id === accountId );
            return currentAccount.tariffs.find(({action}) => action === "EXCHANGE_INTERNAL");
        },
        serviceCommission({ amountFrom, swapInternalTariff, currencyAndNetworkFrom, feeToken }) { //serviceCommissionUSD
            if ( feeToken?.serviceFeeUSD ) {
              return Number(feeToken.serviceFeeUSD)
            }

            const { amount: tariffAmount, minAmount: tariffMinAmount, maxAmount: tariffMaxAmount, type: tariffType } = swapInternalTariff

            if (!currencyAndNetworkFrom || !amountFrom) return undefined

            let currencyUsdRate = currencyAndNetworkFrom.currency.usdRate

            if (tariffType === 'PERCENT') {
                let estimatedTariffAmount = amountFrom * tariffAmount * currencyUsdRate
              //округлить до 2 знаков вверх

                if (estimatedTariffAmount < tariffMinAmount) {
                    estimatedTariffAmount = tariffMinAmount
                } else if (estimatedTariffAmount > tariffMaxAmount) {
                    estimatedTariffAmount = tariffMaxAmount
                }

                return Number(estimatedTariffAmount)
            } else {
                return Number(tariffAmount) //привести к исходящей монете
            }
        },
        // serviceCommission() {
        //   // amountTo = (amountFrom - withdrawFee (в монете from - сеть)) * rate - withdrawFee (в монете to - сеть) - serviceCommission и округляем вниз до 4-х знаков
        // },
        networkTo({ currencyAndNetworkTo }) {
          return currencyAndNetworkTo?.network
        },
        networksCommission({ currencyAndNetworkFrom, networkTo, feeToken }) {
            if ( feeToken?.blockchainFeeFromUSD ) {
              return Number(feeToken.blockchainFeeFromUSD) + Number(feeToken.blockchainFeeToUSD)
            }

            if (!currencyAndNetworkFrom || !networkTo) return undefined;

            let networkFromFee = currencyAndNetworkFrom.network.networkFeeUSD;
            let networkToFee = networkTo.networkFeeUSD;

            return Number(networkFromFee) + Number(networkToFee);
        },
      commonCommission({ serviceCommission }) {
        if (!serviceCommission) {
          serviceCommission = 0
        }
        return Number(serviceCommission).toFixed(3);
      },
        getPrettyAddress() {
            return getPrettyAddress;
        },
        isValid() {
            const { min, max } = this.amountLimits;
            const { amountFrom, amountTo, addressFrom, addressTo, availableBalance, currencyAndNetworkFrom, networkTo } = this;

            const form = {
                amountFrom: !!this.amountFrom && availableBalance >= amountFrom,
                amountTo: !!amountTo && !isNaN(amountTo),
                addressFrom,
                currencyAndNetworkFrom,
                networkTo,
                addressTo,
                minAmount: !this.amountFrom || amountFrom >= min,
                maxAmount: !this.amountFrom || amountFrom <= max,
            };

            return {
                forFeeToken: ['currencyAndNetworkFrom', 'networkTo'].every(field => !!form[field]),
                value: Object.keys(form).every(key => {
                    return !!form[key];
                }),
                form,
            };
        },
        showZeroState({ hasBalance, swapsList = {} }) {
          return !hasBalance && !swapsList.count
        },
        hasBalance({ balancesSummaryFrom = {} }) {
          return Object.keys(balancesSummaryFrom).reduce((acc, key) => {
            if (balancesSummaryFrom[key].length) {
              balancesSummaryFrom[key].forEach(item => {
                acc += item.summary
              })
            }

            return acc
          }, 0)
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.swap.title'),
            subtitle: this.$t('zero-states.swap.subtitle'),
            notifications: [
              this.$t('zero-states.swap.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.swap.actions.api-integration.title'),
                info: this.$t('zero-states.swap.actions.api-integration.info'),
                btnName: this.$t('zero-states.swap.actions.api-integration.btn-name'),
                btnSecondary: true,
                withoutIcon: true,
                apiMethod: '/api-reference/09crosschain_swaps/post_create'
              }
            ],
            type: 'default'
          }
        }
    },
    created() {
        this.getAmountLimits();
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        if (!isAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }

        this.balancesSummaryTo = cloneDeep(this.balancesSummaryFrom)
    },
    async mounted() {
      await this.getBalancesSummary()

      //получение информации о списке операций в этом компоненте, а не в истории, необходимо только для отображения нулевого состояния
      this.swapsList = await this.getSwapTransfersList(
        {
          limit: 10,
          offset: 0,
          sort: "DESC",
          networkFromId: [],
          networkToId: [],
          addressFromId: [],
          addressToId: [],
          amount: [],
          createdAt: [],
          currencyFromId: [],
          currencyToId: [],
          status: [],
          clientId: [],
          id: []
        }
      )
    },
    methods: {
        ...mapActions('balances', [
          'getAccountBalancesPayin',
          'getAccountBalancesBusiness',
          'getAccountBalancesRecurrent'
        ]),
        ...mapActions('swap', [
          'getSwapTransfersList'
        ]),
        ...mapActions('payoutBalances', [
          'fetchPayoutBalances'
        ]),
        ...mapActions('personalAddresses', [
          'getAddressesForPerson'
        ]),
        ...mapActions('crossChain', [
          'getCrosschainSwapsAmountLimits',
        ]),
        ...mapActions('app', [
          'openModal',
        ]),
        ...mapActions('swap', [
          'getSwapTransferToken',
        ]),
        prettyAmountWithHideZeros,
        clearData() {
            this.amountFrom = undefined;
            this.amountTo = undefined;
            this.addressFrom = undefined;
            this.addressTo = undefined;
            this.currencyAndNetworkFrom = undefined;
        },
        async getAmountLimits() {
            const result = await this.getCrosschainSwapsAmountLimits();

            if (result) {
                this.amountLimitsUSD.min = Number(result.min);
                this.amountLimitsUSD.max = Number(result.max);
            }
        },
        async getBalancesSummary() {
          const { accountId, selectedOrganizationId: organizationId, balancesSummaryFrom, balancesSummaryTo, currenciesWithChainFrom, currenciesWithChainTo } = this

          const walletsNames =  [ 'business', 'payin', 'payout', 'personal', 'recurrent' ]

          const balancesRequests = await Promise.all([
            this.getAccountBalancesBusiness({ accountId, organizationId }).catch(() => {}),
            this.getAccountBalancesPayin({ accountId, organizationId }).catch(() => {}),
            this.fetchPayoutBalances({ organizationId }).catch(() => {}),
            this.getAddressesForPerson({ organizationId }).catch(() => {}),
            this.getAccountBalancesRecurrent({ organizationId }).catch(() => {})
          ])

          walletsNames.forEach((walletName, index) => {
            [ [balancesSummaryFrom, currenciesWithChainFrom], [balancesSummaryTo, currenciesWithChainTo] ].forEach((pair) => {
              pair[0][walletName] = pair[1].map(({ currency, network }) => {
                let addresses = []

                if (!!balancesRequests[index]) {
                  addresses = balancesRequests[index].filter(addr => {
                    return currency.id === addr.currencyId && network.id === addr.networkId && addr.riskLevel !== "red" && addr.riskLevel !== "black"
                  })
                }

                return {
                  currencyId: currency.id,
                  networkId: network.id,
                  min_amount: addresses.length ? Math.min.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                  max_amount: addresses.length ? Math.max.apply(Math, addresses.map(({ balance }) => Number(balance))) : 0,
                  summary: addresses.length ? addresses.reduce(( acc, addr ) => {
                    acc += Number(addr.balance)
                    return acc
                  }, 0) : 0,
                  addresses
                }
              })
            })
          })
        },
        openSwapModal() {
            const { openModal, amountFrom, amountTo,addressFrom, currencyAndNetworkFrom, currencyAndNetworkTo, networkTo, addressTo, serviceCommission } = this;
            if (!this.isSwapAllowed) {
                return
            }
            // const test = {
            //   "amountFrom": 20,
            //   "amountTo": 20,
            //   "addressFrom": {
            //     "id": "7f6663eb-69a6-41e8-9db1-2db80fd0b84d",
            //     "currencyId": "03dbd2a5-3148-413d-a26c-8de1103d34a5",
            //     "networkId": "34013069-1766-4146-af38-1300a1ba797b",
            //     "address": "0x3fF53c568988C21E3605a6Af511Bf8f71f333cea",
            //     "tag": "",
            //     "balance": "3404.27",
            //     "alias": null,
            //     "comment": null,
            //     "isBanned": false,
            //     "isActive": true,
            //     "__typename": "OrganizationAddress"
            //   },
            //   "currencyAndNetworkFrom": {
            //     "id": "03dbd2a5-3148-413d-a26c-8de1103d34a5_34013069-1766-4146-af38-1300a1ba797b",
            //     "currency": {
            //       "id": "03dbd2a5-3148-413d-a26c-8de1103d34a5",
            //       "currency": "USDT",
            //       "alias": "Tether USD",
            //       "orderIndex": 2147483647,
            //       "allowDeposit": true,
            //       "allowWithdraw": true,
            //       "allowPayout": true,
            //       "allowInvoice": true,
            //       "allowBusiness": true,
            //       "isFiat": false,
            //       "stablecoin": true,
            //       "usdRate": "1",
            //       "networks": [
            //         {
            //           "id": "34013069-1766-4146-af38-1300a1ba797b",
            //           "name": "ethereum",
            //           "alias": "Ethereum (ERC20)",
            //           "confirmations": 15,
            //           "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
            //           "tagRegex": "",
            //           "underMaintenance": false,
            //           "allowDeposit": true,
            //           "allowWithdrawal": true,
            //           "allowCrosschainSwaps": true,
            //           "allowCrosschainSwapFrom": true,
            //           "allowCrosschainSwapTo": true,
            //           "allowCrosschainTransfer": true,
            //           "isDefault": false,
            //           "contract": "0xdac17f958d2ee523a2206206994597c13d831ec7",
            //           "networkFee": 3.156,
            //           "networkFeeUSD": 3.16,
            //           "minWithdrawal": 0,
            //           "minFreezeTime": 3600,
            //           "maxFreezeTime": 43200,
            //           "__typename": "Network"
            //         },
            //         {
            //           "id": "6b976e2c-cf6e-4601-ba7e-f3df5af86af7",
            //           "name": "bsc",
            //           "alias": "Binance Smart Chain (BEP20)",
            //           "confirmations": 15,
            //           "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
            //           "tagRegex": "",
            //           "underMaintenance": false,
            //           "allowDeposit": true,
            //           "allowWithdrawal": true,
            //           "allowCrosschainSwaps": true,
            //           "allowCrosschainSwapFrom": true,
            //           "allowCrosschainSwapTo": true,
            //           "allowCrosschainTransfer": true,
            //           "isDefault": false,
            //           "contract": "0x55d398326f99059ff775485246999027b3197955",
            //           "networkFee": 0.348,
            //           "networkFeeUSD": 0.35,
            //           "minWithdrawal": 0,
            //           "minFreezeTime": 1800,
            //           "maxFreezeTime": 43200,
            //           "__typename": "Network"
            //         },
            //         {
            //           "id": "5e5add4a-2e2b-4067-bcb3-a65aad748b1a",
            //           "name": "tron",
            //           "alias": "Tron (TRC20)",
            //           "confirmations": 10,
            //           "addressRegex": "^T[1-9A-HJ-NP-Za-km-z]{33}$",
            //           "tagRegex": "",
            //           "underMaintenance": false,
            //           "allowDeposit": true,
            //           "allowWithdrawal": true,
            //           "allowCrosschainSwaps": true,
            //           "allowCrosschainSwapFrom": true,
            //           "allowCrosschainSwapTo": true,
            //           "allowCrosschainTransfer": true,
            //           "isDefault": false,
            //           "contract": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            //           "networkFee": 2.2,
            //           "networkFeeUSD": 2.2,
            //           "minWithdrawal": 0,
            //           "minFreezeTime": 1800,
            //           "maxFreezeTime": 43200,
            //           "__typename": "Network"
            //         },
            //         {
            //           "id": "f0245c34-5871-4244-9714-61eb9b91b57b",
            //           "name": "solana",
            //           "alias": "Solana",
            //           "confirmations": 4,
            //           "addressRegex": "^[0-9a-zA-Z]{32,44}$",
            //           "tagRegex": "",
            //           "underMaintenance": false,
            //           "allowDeposit": false,
            //           "allowWithdrawal": false,
            //           "allowCrosschainSwaps": false,
            //           "allowCrosschainSwapFrom": false,
            //           "allowCrosschainSwapTo": false,
            //           "allowCrosschainTransfer": false,
            //           "isDefault": false,
            //           "contract": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
            //           "networkFee": 1.2,
            //           "networkFeeUSD": 1.2,
            //           "minWithdrawal": 0,
            //           "minFreezeTime": 1800,
            //           "maxFreezeTime": 43200,
            //           "__typename": "Network"
            //         }
            //       ],
            //       "__typename": "Currency"
            //     },
            //     "network": {
            //       "id": "34013069-1766-4146-af38-1300a1ba797b",
            //       "name": "ethereum",
            //       "alias": "Ethereum (ERC20)",
            //       "confirmations": 15,
            //       "addressRegex": "^(0x)[0-9A-Fa-f]{40}$",
            //       "tagRegex": "",
            //       "underMaintenance": false,
            //       "allowDeposit": true,
            //       "allowWithdrawal": true,
            //       "allowCrosschainSwaps": true,
            //       "allowCrosschainSwapFrom": true,
            //       "allowCrosschainSwapTo": true,
            //       "allowCrosschainTransfer": true,
            //       "isDefault": false,
            //       "contract": "0xdac17f958d2ee523a2206206994597c13d831ec7",
            //       "networkFee": 3.156,
            //       "networkFeeUSD": 3.16,
            //       "minWithdrawal": 0,
            //       "minFreezeTime": 3600,
            //       "maxFreezeTime": 43200,
            //       "__typename": "Network"
            //     },
            //     "pairSummary": {
            //       "min_amount": 7,
            //       "max_amount": 3409000.69,
            //       "summary": 3412411.96
            //     }
            //   },
            //   "networkTo": {
            //     "id": "5e5add4a-2e2b-4067-bcb3-a65aad748b1a",
            //     "name": "tron",
            //     "alias": "Tron (TRC20)",
            //     "confirmations": 10,
            //     "addressRegex": "^T[1-9A-HJ-NP-Za-km-z]{33}$",
            //     "tagRegex": "",
            //     "underMaintenance": false,
            //     "allowDeposit": true,
            //     "allowWithdrawal": true,
            //     "allowCrosschainSwaps": true,
            //     "allowCrosschainSwapFrom": true,
            //     "allowCrosschainSwapTo": true,
            //     "allowCrosschainTransfer": true,
            //     "isDefault": false,
            //     "contract": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            //     "networkFee": 2.2,
            //     "networkFeeUSD": 2.2,
            //     "minWithdrawal": 0,
            //     "minFreezeTime": 1800,
            //     "maxFreezeTime": 43200,
            //     "__typename": "Network"
            //   },
            //   "addressTo": {
            //     "id": "a1788cbf-ac8a-437d-b805-ae71f25d8f64",
            //     "accountId": "316a59ea-be39-4eaa-9392-6fda708f24d8",
            //     "currencyId": "03dbd2a5-3148-413d-a26c-8de1103d34a5",
            //     "networkId": "5e5add4a-2e2b-4067-bcb3-a65aad748b1a",
            //     "address": "TEdLzXjk4jRCwRW8ezsych6fe4mKfXZtNv",
            //     "tag": "",
            //     "balance": "810",
            //     "alias": "IP test",
            //     "comment": "My main address",
            //     "isBanned": false,
            //     "isActive": null,
            //     "isArchived": false,
            //     "__typename": "AccountBalance"
            //   },
            //   "serviceCommission": 0.8
            // }
            //
            // openModal({
            //     name: 'SwapModal',
            //     payload: test,
            // });

            openModal({
                name: 'SwapModal',
                payload: {
                    amountFrom,
                    amountTo,
                    addressFrom,
                    currencyAndNetworkFrom,
                    currencyAndNetworkTo,
                    networkTo,
                    addressTo,
                    serviceCommission,
                },
            })
        },
        setAmountFromMax() {
            const { availableBalance, amountLimits } = this;

            if (availableBalance) {
                this.amountFromModel = cloneDeep(availableBalance > amountLimits.max ? amountLimits.max : availableBalance);
            }
        },
        showHistoryRoute() {
            if (!this.isHistoryAllowed) {
                return
            }
            this.$router.push({
                name: 'swapHistory',
            });
        },
        openResultModal(result) {
            const { openModal, _uid } = this

            const modalName = !!result?.success ? `BaseSuccessModal_${_uid}` : `BaseErrorModal_${_uid}`

            this.$nextTick(() => {
                openModal({
                    name: modalName,
                });

                if (!!result?.success)  {
                    this.clearData();
                }
            });
        },
      async setRecallForToken() {
        this.timer = 30

        this.commissionLoading = true

        const minAmount = this.amountLimits?.min

        const payload = {
          accountId: this.accountId,
          currencyFromId: this.currencyAndNetworkFrom.currency.id,
          currencyToId: this.currencyAndNetworkTo.currency.id,
          networkFromId: this.currencyAndNetworkFrom.network.id,
          networkToId: this.currencyAndNetworkTo.network.id,
        }

        const { activeAmountFrom } = this

        if (activeAmountFrom) {
          let amount // если количество на вывод меньше требуемого (minAmount) то делаем запрос для minAmount чтобы получить стартовый курс

          if (!this.amountFrom || (!this.swapRate && this.amountFrom < minAmount)) {
            amount = minAmount.toString()
          } else {
            amount = this.amountFrom.toString()
          }

          payload.amountFrom = amount
        } else {
          payload.amountTo = this.amountTo.toString()
        }

        const data = await this.getSwapTransferToken(payload)

        if ( !!data.result ) {
          this.feeToken = data.result
        }

        const { price, amountTo, amountFrom } = this.feeToken

        if (price) {
          this.swapRate = Number(this.feeToken.price)

          const { activeAmountFrom, swapRate } = this

          if (activeAmountFrom) {
            if (!!data.result && this.amountFrom >= minAmount) {
              this.amountTo = (this.amountFrom && amountTo) ? Number(amountTo) : 0
            } else {
              this.amountTo = prettyAmountWithHideZeros(this.amountFrom * swapRate, 4)
            }
          } else {
            if (!!data.result) {
              this.amountFrom = (this.amountTo && this.feeToken) ? Number(amountFrom) : 0
            } else {
              this.amountFrom = prettyAmountWithHideZeros(this.amountTo / swapRate, 4)
            }
          }
        }

        setTimeout(() => {
          this.commissionLoading = false
        }, 1000)

        if (this.tokenTimerInterval) {
          clearInterval(this.tokenTimerInterval)
        }

        this.tokenTimerInterval = setInterval(() => {
          this.timer--

          if (this.timer === 0) {
            this.setRecallForToken()
          }
        }, 1000)
      },
    },
};
</script>
<style lang="scss">
.swap {
  padding: 0 30px 30px 30px;

  font-family: var(--new-front-font-family);
  @media screen and (max-width: 576px) {
    padding: 0 10px 10px 10px;
  }
  &__title-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--margin-h1-bottom);

    .history-btn {
      display: none;
    }

    @include below_phone() {
      .swap__title {
        font-size: $h3;
      }
    }
    @include below_375() {
      padding: 0 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-secondary);
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: $h3;
    line-height: $h3;
    color: var(--new-front-text-secondary-bg-secondary);

    @include below_375() {
      padding: 0 20px;
    }
  }

  &__container {
    //max-width: 1415px;
    background: var(--new-front-bg-secondary);
    border-radius: 20px;
    box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
    //margin-top: var(--margin-container-top);
    margin-bottom: var(--margin-container-bottom);

    padding: 30px 0 10px;

    .header {
      display: flex;
      align-items: center;
      justify-content: end;

      padding: 0 30px;

      .label {
        font-family: var(--new-front-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: $h4;
        line-height: $h4;

        padding-right: 10px;

        color: var(--primary-font-color);
      }

      @include below_880() {
        flex-direction: column;
        padding: 0;

        .label {
          margin-bottom: 10px;
        }
      }
    }

    .swap-dashboard {
      padding: 0 30px;

      @include below_360() {
        padding: 0 10px;
      }
    }

    .line {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;

      gap: 10px;

      .item {
        margin-top: 30px;
        flex-grow: 1;
        flex-basis: 0;

        min-width: 320px;

        &.amount-input {
          .label-right {
            .available-balance {
              font-style: normal;
              font-weight: 400;

              text-align: center;

              color: var(--new-front-secondary-font-color);

              .value {
                color: var(--new-front-primary-font-color);
              }
            }
          }
          .bottom-label {
            display: flex;
            justify-content: right;

            font-family: var(--new-front-font-family);
            font-style: normal;
            font-weight: 500;

            .limits-wrapper {
              width: 100%;
              display: flex;
              justify-content: end;

              gap: $h6;

              .minimum-amount,.maximum-amount {
                &.error {
                  .info {
                    color: red;
                  }
                  .value {
                    color: red;
                  }
                }
              }

              @include below_phone() {
                display: none;
              }
            }



            .available-balance {
              display: none;

              @include below_phone() {
                width: 100%;
                display: flex;
                justify-content: end;
              }

              .info {
                color: var(--new-front-secondary-font-color);
                margin-right: $h6;
              }
            }

            .info {
              color: var(--new-front-secondary-font-color);
            }
            .value {
              color: var(--new-front-primary-font-color);
            }
          }
        }

        @include below_880() {
          width: 100%;
          margin-top: 20px;
        }
      }

      .no-address-warning {
        position: absolute;
        top: 105px;
        left: 0;
      }
    }
    .line.mobile-history-line {
      justify-content: end;

      .history-btn {
        display: none;

        @include below_880() {
          display: block;
        }
      }
    }
    .line.currency-and-network-line {
      flex-wrap: nowrap;
      gap: 10px;
      align-items: end;

      .currency-and-network-select {
        flex-grow: 1;
      }
      .history-btn {
        flex-shrink: 1;
      }
      @include below_880() {
        .history-btn {
          display: none;
        }
      }
    }

    .network-select {
      .network-select-selected, .network-select-option-item {
        display: flex;
        align-items: center;
        gap: 7px;

        min-height: 32px;
      }
    }

    .swap-divider {
      display: flex;
      justify-content: center;

      width: 100%;

      margin: 80px 0 30px;
    }

    .footer {
      padding: 18px 30px;
      margin: 30px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      border-radius: 12px;

      background-color: var(--new-front-bg-primary);

      gap: 30px;

      font-size: $h4;
      line-height: $h3;

      .action {
        .execute-btn {
          width: 260px;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        font-family: var(--new-front-font-family);
        font-style: normal;

        flex-grow: 1;
        gap: 30px;

        .service, .network {
          display: flex;
          flex-direction: column;
          gap: 10px;

          &.balance-error {
            background: var( --new-front-bg-rejected-bg-secondary);
            padding: 8px 16px;
            border-radius: 12px;

            .value {
              .sublabel {
                color: var(--new-front-status-text-error);
                max-width: 70%;
              }
            }
          }
          .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            gap: 0 10px;

            .label {
              color: var(--new-front-text-secondary-bg-secondary);
              font-weight: 400;

              white-space: nowrap;
            }
            .value {
              color: var(--new-front-text-primary-bg-secondary);
              font-weight: 500;
            }
          }
        }
      }

      @include below_880() {
        align-items: start;
        flex-direction: column;
      }
      @include below_680() {
        padding: 18px;

        .action {
          width: 100%;

          .execute-btn {
            width: 100%;
          }
        }

        .info {
          flex-direction: column;
          align-items: start;
          gap: 10px;

          width: 100%;

          .service, .network  {
            display: flex;
            flex-wrap: wrap;

            .value {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }

            .item {
              width: 100%;
            }
          }
        }
      }
      @include below_phone() {
        font-size: $h6;
        font-weight: $h5;
      }
      @include below_360() {
        margin: 10px;
        .info {
          gap: 0;

          .item {
            margin: 5px 5px 5px 0;
          }
        }
      }
    }
  }
}

.comingSoon {
  height: 100%;
  margin-top: -170px;
}
</style>
