<template>
  <div>
    <preloader v-if="isLoading || !isInit"/>
    <div class="payout-wallet" v-else>
<!--      <h1 class="payout-wallet__title">-->
<!--          {{ $t('payout-wallet.title') }}-->
<!--          <common-info-modal-->
<!--                  :description="$t('info_modals.transactions.description')"-->
<!--          />-->
<!--      </h1>-->
<!--      <h2 class="payout-wallet__subtitle">{{ $t('payout-wallet.subtitle') }}</h2>-->
      <RouterView name="balance"/>

<!--      модалки-->
      <PayoutModalTransfer/>
      <PayoutModalDeposit/>
      <PayoutModalUploadCSV/>
      <PayoutModalRegistryExecute/>
      <CreateWithdrawalRequestModalPayout/>
      <CreateWithdrawalRequestModal/>
      <PayoutModalTransfer/>
      <NewAddressModal/>
      <ChangeAddressBook/>
      <TwoFaRestriction />
      <TwoFaConfirmation :modal-name="'CreateWithdrawalRequestModalPayout'"/>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import PayoutModalRegistryExecute from "../components/modals/payout-transfer/PayoutModalRegistryExecute";
import Container from "../components/common/Container";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import btn from '../components/common/btn';
import CurrencyImg from "../components/elements/CurrencyImg";
import CopyString from "../components/elements/CopyString";
import PayoutModalTransfer from "../components/modals/payout-transfer/PayoutModalTransfer";
import PayoutModalDeposit from "../components/modals/payout-transfer/PayoutModalDeposit";
import PayoutModalUploadCSV from "../components/modals/payout-transfer/PayoutModalUploadCSV";
import pagination from "../components/common/pagination";
import PayoutWalletRegistryHeader from "../components/elements/payoutWallet/PayoutWalletRegistryHeader";
import PayoutWalletRegistryPreview from "../components/elements/payoutWallet/PayoutWalletRegistryPreview";
import PayoutWalletRegistryFilterByDate from "../components/elements/payoutWallet/PayoutWalletRegistryFilterByDate";
import PayoutWalletBalance from "../components/elements/payoutWallet/PayoutWalletBalance";
import CreateWithdrawalRequestModalPayout
    from "../components/modals/payout-transfer/CreateWithdrawalRequestModalPayout";
import CreateWithdrawalRequestModal from "/public/components/modals/requests/CreateWithdrawalRequestModal";
import NewAddressModal from '/public/components/modals/addressBook/NewAddressModal';
import ChangeAddressBook from '/public/components/modals/addressBook/ChangeAddressBook';
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation";
import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";
import { isAlbertCrypto } from "@/config";

export default {
    name: "payout-wallet",
    title: 'payout-wallet.title',
    components: {
      CommonInfoModal,
      TwoFaConfirmation,
      TwoFaRestriction,
      CreateWithdrawalRequestModalPayout,
      PayoutWalletBalance,
      PayoutWalletRegistryFilterByDate,
      PayoutWalletRegistryPreview,
      PayoutWalletRegistryHeader,
      PayoutModalUploadCSV,
      PayoutModalRegistryExecute,
      PayoutModalDeposit, PayoutModalTransfer, CopyString, CurrencyImg, Container, btn, Preloader, pagination,
      NewAddressModal,
      ChangeAddressBook,
      CreateWithdrawalRequestModal
    },
    rulesAlias: 'payout_balances',
    data() {
        return {
            pageSize: 7,
            page: 1,
        };
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_payout_balances;
        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    methods: {
        ...mapActions('payoutBalances', ['fetchPayoutBalances', 'fetchRegistries', 'fetchRegistry']),
        ...mapActions('app', ['openModal']),
        registryStatus(status) {
            return this.$t(this.$options.filters.registryStatusType(status));

        },
        switchTabBalance() {
            if (this.$route.name === 'payout-wallet-balance') return;
            this.$router.push({name: 'payout-wallet-balance'});
        },
        switchTabRegistry() {
            if (this.$route.name === 'payout-wallet-registry') return;
            this.$router.push({name: 'payout-wallet-registry'});
        },
        openPayoutRegistryExecuteModal({registryId, registryTitle}) {
            this.openModal({name: 'PayoutModalRegistryExecute', payload: {registryId, registryTitle}});
        },
        fetchRegistryData(value) {
            const accountId = this.accounts[0].accountId;
            if (this.registriesData.registriesItems.some((item) => item.registry.id === value.val)) {
                return;
            }
            this.fetchRegistryInfoAction({accountId: accountId, registryId: value.val});
        },
        catchPagination(page) {
            this.page = page;
        },
        changePage(pageNumber) {

            const {
                $route,
                $router,
                fetchRegistries,
            } = this;

            fetchRegistries({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    computed: {
        ...mapGetters('app', [
            'getCurrencyNameById',
        ]),
        ...mapState('payoutBalances', ['payoutBalances', 'registriesData']),
        ...mapState('app', ['isLoading', 'isInit']),
        ...mapState('accounts', ['accounts']),
      ...mapGetters('organizations', ['userRoleRules']),
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        fullRegistryData() {
            let result = this.registriesData.registries.map((i) => {
                const registryItem = this.registriesData.registriesItems.find((item) => item.registry.id === i.id);
                i.registryItem = registryItem?.items ?? [];
                return i;
            });
            return result;
        },
        paginateSortedBalances() {
            const paginatedBalances = this.payoutBalances.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
            return paginatedBalances;
        },

        payoutBalancesCount() {

            return this.payoutBalances?.length ?? 0;
        },
        isRegistryTabActive() {
            return this.$route.name === 'payout-wallet-registry';
        },
        isBalanceTabActive() {
            return this.$route.name === 'payout-wallet-balance';
        },
    },
};
</script>

<style lang="scss" scoped>
.payout-wallet {
  padding: 0 30px 30px 30px;
  font-family: var(--new-front-font-family);


  &__title-text {
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--grey-300);
    margin-bottom: 5px;
  }

  &__value {
    font-family: var(--new-front-font-family);
    font-style: normal;
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }


  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }
  }

  &__container {
    margin-top: 10px;
    margin-bottom: 10px;

    &-top {
      padding: 30px 35px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
    }
  }

  &__currency {
    &-container {
      display: flex;
      align-items: center;
    }

    &-image {
      height: 26px;
      width: 26px;
      margin-right: 16px;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 40px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-bottom: $p5;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: $h3;
    color: var(--new-front-text-secondary-bg-modal-primary);
    margin-bottom: 30px;
  }

  &__tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 66px;
    border-radius: 16px;
    cursor: pointer;


    &-underlay {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      min-height: 66px;
      border-radius: 16px;
      justify-content: flex-start;

      &_registry {
        justify-content: flex-end;
      }
    }

    &_right {
      position: absolute;
      right: 5px;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    &_left {
      position: absolute;
      left: 0;
      width: calc(50% + 25px);
      padding-left: 25px;
      background: var(--grey-200);
      z-index: 1;
      border-top: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);

    }


    &_left-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }

    &_right-active {
      position: relative;
      z-index: 2;
      background: var(--white-100);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
      border: none;
    }
  }

  &__tab-container {
    min-height: 76px;
    padding: 10px;
    border-radius: 15px;
    background: var(--white-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &__preview {
    width: 100%;
  }


  &__mobile {
    display: block;


    ::v-deep .at-collapse {
      border: 1px solid var(--border-color);
      border-right: none;
      border-left: none;
    }

    ::v-deep .at-collapse__content {
      padding: 0;
    }

    ::v-deep .at-collapse__header {
      background: var(--secondary-color);
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 10px;
      padding-top: 0;
      padding-bottom: 0;
    }

    ::v-deep .at-collapse__icon {
      right: 10px;
      top: 17px;
      font-size: 20px;
      left: unset;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    ::v-deep .at-collapse__item--active > .at-collapse__header .at-collapse__icon {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }

  }

  &__registry-title {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__registry-status-text {
    font-family: var(--new-front-font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__registry-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    gap: 10px;
  }


  &__registry-currency-image {
    width: 26px;
    height: 26px;
  }

  &__registry-image-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__registry-info-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 55px;
    align-items: center;
    border-bottom: var(--border-table);
  }
}
@media screen and (max-width: 950px) {
  .payout-wallet {
    padding: 0 10px 10px 10px;
    &__title {
      font-size: $h3;
    }

    &__subtitle {
      font-size: $h6;
    }
  }
}
@media screen and (max-width: 375px) {

  .payout-wallet {
    &__title {
      padding-left: 10px;
    }

    &__subtitle {
      padding-left: 10px;
    }
  }

}

</style>
