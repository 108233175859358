<template>
  <div class="donations">
    <h1 class="donations__title">
      {{ $t('donations.title') }}
    </h1>
    <p class="donations__paragraph">
      {{ $t('donation.details') }}
      <br>
    </p>
    <div class="donations__wrapper">
      <Container :class="['donations__container', pages.length ? '' : 'donations__container_empty-top']">
        <div class="donations__button-container">
          <AtButton
              class="donations__new-button"
              size="small"
              type="primary"
              icon="icon-plus"
              @click="openNewDonationModal"
          >
            {{ $t('donations.create_donation') }}
          </AtButton>
        </div>
        <div class="donations__header">
          <div class="donations__header-item_color"/>
          <p class="donations__header-item donations__header-item_title"> {{ $t('donations.table_header.title') }}</p>
          <p class="donations__header-item">URL</p>
          <p class="donations__header-item"> {{ $t('donations.table_header.total_sum_24h') }}</p>
          <div class="donations__header-item"></div>
        </div>
      </Container>
      <donation-preview v-for="page in pages" :key="page.id" :page="page" :pageNumber="currentPage"
                        v-if="pages.length"/>
      <pagination :size="'small'"
                  :total="count"
                  :page-size="pagesCount"
                  :current="currentPage"
                  @page-change="changePage" v-if="pages.length"/>
      <Container class="donations__container donations__container_empty" v-else>
        <EmptyTable/>
      </Container>

    </div>
    <DonationModal/>
    <RemoveDonationModal/>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from "vuex";
import Container from "../components/common/Container";
import AtButton from 'at-ui/src/components/button';
import DonationModal from "../components/modals/donation/DonationModal";
import CopyString from "../components/elements/CopyString";
import pagination from "../components/common/pagination";
import RemoveDonationModal from "../components/modals/donation/RemoveDonationModal";
import DonationPreview from "../components/elements/donations/donations-preview";
import EmptyTable from "../components/elements/EmptyTable";
import { isAlbertCrypto } from "@/config";

export default {
    name: "DonationsPage",
    title: 'donations.title',
    rulesAlias: 'donations',
    components: {
        DonationPreview, RemoveDonationModal, CopyString, DonationModal, Container, AtButton, pagination, EmptyTable,
    },
    methods: {
        ...mapActions('donations', [
            'fetchPreCheckoutPages',
        ]),
        openNewDonationModal() {
            this.$router.history.push('/create-donation');
        },
        changePage(pageNumber) {

            const {
                $route,
                $router,
                fetchPreCheckoutPages,
            } = this;

            fetchPreCheckoutPages({
                route: $route,
                router: $router,
                page: pageNumber,
            });
        },
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        if (!isAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    computed: {
        ...mapState('donations', ['pages', 'count', 'pagesCount']),
        ...mapGetters('organizations', ['userRoleRules']),
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
    },
    beforeMount() {
        const {$route} = this;
        this.fetchPreCheckoutPages({
            route: $route,
            showUrlFilters: false,
        });
    },
};

</script>

<style scoped lang="scss">
.donations {
  max-width: 1420px;
  padding-top: var(--padding-top-container);
  padding-left: var(--padding-left-container);
  padding-right: var(--padding-right-container);

  &__title {
    margin-bottom: 13px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h1);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__paragraph {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    margin-bottom: 50px;
    color: var(--primary-font-color);
  }

  &__wrapper {
    padding-bottom: 10px;
  }

  &__color {
    width: 12px;
    background: var(--bg-color);
    border-radius: 8px;
    height: 100%;
    max-height: 61px;
    margin-left: 6px;
    margin-right: 22px;
  }

  &__container {
    margin-bottom: 10px;
    border-radius: 20px 20px 0 0;

    &_empty {
      border-radius: 0 0 20px 20px;
      margin: 0;
    }

    &_empty-top {
      margin: 0;
    }
  }

  &__link {
    ::v-deep .copy-string__string {
      color: var(--blue-300);
    }
  }

  &__item-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  &__item-container {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 1fr 1fr;
    padding-right: 40px;
    min-height: 75px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__header {
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr;
    padding-right: 40px;
    align-items: center;
    min-height: 46px;
    background: var(--main-page-line);
    border: 1px solid var(--border-color);
    border-radius: 0px;
    color: var(--primary-font-color);

    &-item {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      font-weight: 300;
      color: var(--primary-font-color);

      &_color {
      }

      &_title {
        color: var(--primary-font-color);
      }
    }
  }


  &__item {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    font-weight: 300;

    &_button {
      justify-self: flex-end;
    }


    &_title {
      display: flex;
      gap: 22px;
      height: 100%;
      align-items: center;
      padding-left: 6px;
    }

    &_link {

    }

    &_turnover {

    }

    &_button {

    }

  }


  &__button-container {
    padding-left: 40px;
    padding-right: 40px;
    min-height: 77px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    width: 60px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: all 0.3s ease-in;

    ::v-deep .at-btn__icon {
      font-size: 20px;
      line-height: 1;
      color: var(--primary-font-color);
    }

    &:hover {
      border: 1px solid var(--blue-400);

      ::v-deep .at-btn__icon {
        color: var(--blue-400);
      }
    }
  }

  &__detailed-link {
    height: 40px;
    width: 100%;
    max-width: 115px;
    font-weight: 400;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-300);
    transition: all 0.3s ease-in;

    &:hover {
      border: 1px solid var(--blue-400);
    }
  }

  &__new-button {
    height: 46px;
    max-width: 211px;
    width: 100%;
    background: #0059F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.3s ease-in;
    border: none;

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    ::v-deep .at-btn__icon, .at-btn__loading {
      font-size: 24px;
    }

    &:hover {
      background: var(--blue-400);
    }
  }
}

@media screen and (max-width: 768px) {
  .donations {
    &__header {
      display: none;
    }

    &__wrapper {
      background: var(--secondary-color);
      border-radius: 14px;
      padding: 10px;

    }

    &__container {
      border-radius: 14px;
      margin-top: 0;
      background: var(--secondary-color);
      margin-bottom: 50px;
      position: relative;
      overflow: visible;

      &:after {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 0;
        outline: 1px solid var(--border-color);

        width: 100%;
      }

      &_empty {
        background: var(--white-100);
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__button-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__new-button {
      max-width: 100%;
      justify-content: center;


    }

    &__detailed-link {
      max-width: unset;
    }

    &__color {
      grid-area: color;
      max-height: unset;
      margin-left: 0;
    }

    &__item {
      &-container {
        border-radius: 14px;
        grid-template-columns: min-content 1fr 1fr;
        grid-template-areas: 'color title turnover'
                              'color link link'
                              'color button button';
        grid-row-gap: 20px;
        padding: 10px;
      }

      &_button {
        justify-self: flex-start;
        grid-area: button;
        width: 100%;
      }

      &_title {
        display: flex;
        gap: 22px;
        height: 100%;
        align-items: center;
        padding-left: 0;
        grid-area: title;
      }

      &_link {
        grid-area: link;
      }

      &_turnover {
        grid-area: turnover;
        justify-self: flex-end;
      }

      &-button-container {
        justify-content: space-between;
        width: 100%;
        gap: 10px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .donations {
      &__title {
        padding-left: 10px;

      }

      &__paragraph {
        padding-left: 10px;
      }
    }
  }

}
</style>
