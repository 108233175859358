<script>

  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import WidgetsList from "@/public/components/elements/widgets/WidgetsList.vue";
  import WidgetDeleteConfirmation from "@/public/components/elements/widgets/WidgetDeleteConfirmation.vue";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import {
    MANAGE_CREATE_TYPE,
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL
  } from "@/public/pages/widgets/constants";
  import {fetchWidgetHost} from "@/public/store/modules/widgetsManage/actions/fetchWidgetHost";
  import WidgetApiKeysWarning from "@/public/components/elements/widgets/WidgetApiKeysWarning.vue";
  import { isAlbertCrypto } from "@/config";

  export default {
    name: "widgets",
    components: {
      WidgetApiKeysWarning,
      WidgetDeleteConfirmation,
      WidgetsList,
      BaseButton,
      NewSpinner: () => import (/* webpackChunkName: "/public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
      SearchField: () => import(/* webpackChunkName: "public/components/elements/business-walet/Select/Search" */ '/public/components/elements/business-walet/Select/Search'),
    },
    data () {
      return {
        searchModel: null
      }
    },
    computed: {
      ...mapState('widgetsManage', ['widgets', 'isLoading']),
      ...mapGetters('organizations', ['userRoleRules']),
      ...mapState('user', [
        'apiKeys',
      ]),

      list () {
        if (this.searchModel) {
          return this.widgets.filter(item => item.alias.toLowerCase().includes(this.searchModel.toLowerCase()))
        }
        return this.widgets
      },
      isCreateDisabled () {
        return !this.userRoleRules['iframe_widgets'].entities.create || !this.userRoleRules['iframe_widgets'].entities.delete
      }
    },
    methods: {
      ...mapActions('widgetsManage', ['fetchWidgets', 'fetchWidgetHost']),
      ...mapMutations('widgetsManage', ['setManageType', 'clearManageState', 'setAlias']),
      ...mapActions('user', [
        'fetchApiKeys',
      ]),
      ...mapActions('app', ['openModal']),
      goToManage() {
        if (!this.apiKeys.length) {
          this.openModal({
            name: 'WidgetApiKeysWarning'
          });
          return
        }
        this.clearManageState();
        this.setManageType(MANAGE_CREATE_TYPE)
        this.setAlias(`Widget - ${this.widgets.length + 1}`)
        this.$router.push('/widgets/manage')
      }
    },
    async created() {
      const isAllowed = this.userRoleRules['iframe_widgets'].isAllowed;
      const isShowAllowed = this.userRoleRules['iframe_widgets'].entities.view
      if (!isAllowed || !isShowAllowed) {
        this.$Message.error(this.$t('rules.not_allowed'));
        if ( isAlbertCrypto ) {
          await this.$router.push('/merchant/business-wallet');
        } else {
          await this.$router.push('/merchant/payments');
        }
        return
      }
      await this.fetchApiKeys();
      await this.fetchWidgetHost()
      await this.fetchWidgets()
    }
  }
</script>

<template>
<div class="widgets-page">
  <div class="widgets">
<!--    <div class="title">-->
<!--      {{ $t('widgets.title') }}-->
<!--      <span class="title__count">{{ widgets.length }}</span>-->
<!--    </div>-->
    <div class="actions">
      <search-field
          :placeholder="`${$t('transactions.search')}...`"
          v-model="searchModel"
      />
      <base-button :disabled="isCreateDisabled" @click="goToManage" :label="$t('widgets.addWidget')" />
    </div>
    <div class="spinner">
      <new-spinner v-if="isLoading"/>
    </div>
    <WidgetsList v-if="!isLoading" :list="list" />
  </div>
  <WidgetDeleteConfirmation />
  <WidgetApiKeysWarning />
</div>
</template>

<style scoped lang="scss">
.spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.widgets-page {
  padding: 0 30px 30px;
  width: 100%;
  font-family: $Gilroy;
  @media (max-width: 768px) {
    padding: 0 10px 10px;
  }
}
.widgets {
  border-radius: 30px;
  border: 1px solid var(--border-border-neutral-variant);
  background: var(--bg-background-secondary);
  padding: 30px 0;
}
.title {
  color: var(--text-text-primary);
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  padding: 35px 30px 30px 30px;
  @media (max-width: 768px) {
    padding: 20px 20px 15px 20px;
    font-size: 20px;
  }
  &__count {
    color: var(--text-color-text-secondary);
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
}
.actions {
  padding: 0 30px 30px 30px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
  border-bottom: 1px solid var(--border-devider-primary);
  @media (max-width: 768px) {
    padding: 0 20px 20px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
::v-deep {
  .search-input {
    border: 1px solid var(--border-border-input-primary-default, #464646) !important;
    background: var(--bg-background-search, #1D1D1D) !important;
  }
}
</style>
