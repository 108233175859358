<script >
    // import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";

    import Container from "@/public/components/common/uiKit/Container.vue";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
    import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import ListItem from "@/public/components/elements/collect-profits/ListItem.vue";
    import CreateSettingsModal from "@/public/components/modals/collecting-profits/CreateSettingsModal.vue";
    import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
    import Downloader from "@/public/components/elements/personal-addresses/Select/Downloader/index.vue";
    import ListMobileItem from "@/public/components/elements/collect-profits/ListMobileItem.vue";
    import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
    import HistoryButton from "@/public/components/common/uiKit/history/index.vue";
    import ChangeAddressBook from "@/public/components/modals/addressBook/ChangeAddressBook.vue";
    import NewAddressModal from "@/public/components/modals/addressBook/NewAddressModal.vue";
    import DeleteSettingModal from "@/public/components/modals/collecting-profits/DeleteSettingModal.vue";
    import UpdateSettingsModal from "@/public/components/modals/collecting-profits/UpdateSettingsModal.vue";
    import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";
    import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
    import { isEmpty } from "lodash";

    export default {
        name: "CollectingProfits",
        rulesAlias: "collecting",
        components: {
            TwoFaRestriction,
            CommonInfoModal,
            UpdateSettingsModal,
            DeleteSettingModal,
            NewAddressModal,
            ChangeAddressBook,
            HistoryButton,
            FilterMobile,
            ListMobileItem,
            Downloader,
            TwoFaConfirmation,
            CreateSettingsModal,
            NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
            ListItem, BaseButton, RefreshButton, OrphanSearch, BusinessWalletNetworkSelect, Container,
            ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
        },
        data () {
            return {
                networksValue: null,
                currencyValue: null,
                selectedDownloadType: undefined,
                history: {}
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies', 'isLoading']),
            ...mapState('collectingProfits', ['settingsList']),
            ...mapGetters('app', [
                'getSelectedOrganization'
            ]),
            ...mapGetters('organizations', ['userRoleRules']),
            ...mapState('user', {
                userOtpEnabled: state => state.user.otpToken,
            }),
            isActionsAllowedShow () {
                return this.userRoleRules[this.$options.rulesAlias].entities.manage_settings
            },
            isHistoryAllowedShow () {
                return this.userRoleRules[this.$options.rulesAlias].entities.view_history
            },
            filteredCurrencies () {
                const {
                    currencies,
                } = this;
                let sortedCurrencies = currencies.filter(({isFiat}) => !isFiat);

                if (this.networksValue) {
                    sortedCurrencies = sortedCurrencies.filter((i) => {
                        return i.networks.find(({id}) => id === this.networksValue.id);
                    });
                    return sortedCurrencies;
                }

                return sortedCurrencies;
            },
            filteredNetworks() {
                const {
                    currencies,
                } = this;

              const networks = {};

              for(const currency of currencies) {
                if(!currency.allowBusiness) {
                  continue;
                }

                for(const network of currency.networks) {
                  if(!network.allowDeposit) {
                    continue;
                  }

                  networks[network.id] = network;
                }
              }

              return Object.values(networks);
            },
            currencySelectPlaceholder () {
                return this.$t('orders.select_currencies')
            },
            networkSelectPlaceholder () {
                return this.$t('orders.select_network')
            },
            showZeroState({ isEmptyFilters, settingsList, hasHistory }) {
              return isEmptyFilters && !settingsList.length && !hasHistory
            },
            isEmptyFilters({ networksValue, currencyValue }) {
              return isEmpty(networksValue) && isEmpty(currencyValue)
            },
            zeroStateMeta() {
              const { isActionsAllowedShow } = this

              const meta = {
                title: this.$t('zero-states.profit-collection.title'),
                subtitle: this.$t('zero-states.profit-collection.subtitle'),
                notifications: [
                  this.$t('zero-states.profit-collection.notifications.first-line')
                ],
                actions: [],
                type: 'default'
              }

              if (isActionsAllowedShow) {
                meta.actions.push(
                  {
                    title: this.$t('zero-states.profit-collection.actions.create-collection.title'),
                    info: this.$t('zero-states.profit-collection.actions.create-collection.info'),
                    btnName: this.$t('zero-states.profit-collection.actions.create-collection.btn-name'),
                    action: 'open-create-collection-modal'
                  }
                )
              }

              return meta
            },
            hasHistory({ history = [] }) {
              return !!history.length
            }
        },
        created() {
          this.$router.push({ name: 'payments' })
            // const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
            // if (!isAllowed) {
            //   this.$Message.error(this.$t('rules.not_allowed'));
            //   this.$router.push('/merchant/payments');
            //   return
            // }
            // this.getHistory()
            // this.loadSettings()
            // this.getMinimumAmount()
        },
        watch: {
            selectedDownloadType(v) {
                if (!!v) {
                    const payload = JSON.stringify({
                        'organizationId': this.getSelectedOrganization.id,
                        'networkId': this.networksValue?.id ? this.networksValue?.id : null,
                        'currencyId': this.currencyValue?.id ? this.currencyValue?.id : null,
                    })

                    window.open(`/downloads/${v.id}-collecting-settings?_=` + payload)
                    this.selectedDownloadType = undefined
                }
            }
        },
        methods: {
            ...mapActions('collectingProfits', ['getCollectingSettings', 'getCollectingHistory', 'getMinimumAmount']),
            ...mapActions('app', [
                'openModal',
            ]),
            goToHistory () {
              this.$router.push('/collecting-history')
            },
            openCreateModal () {
                if (!this.userOtpEnabled) {
                    this.openModal({
                        name: 'TwoFaRestriction',
                    });
                    return;
                }

                if (!this.isActionsAllowedShow) {
                    return
                }
              this.openModal({
                  name: 'CreateSettingsModal'
              })
            },
            handleSelectNetwork(value) {
                this.networksValue = value
                this.currencyValue = null;
                this.loadSettings()
            },
            async loadSettings () {
                const variables = {
                    currencyId: this.currencyValue ? this.currencyValue.id : null,
                    networkId: this.networksValue ? this.networksValue.id : null,
                }
                await this.getCollectingSettings(variables)
            },
            handleSelectCurrency(value) {
                this.currencyValue = value;
                this.loadSettings()
            },
            catchPagination(page) {
                this.page = page;
            },
            refresh () {
                this.currencyValue = null;
                this.networksValue = null;
                this.loadSettings()
            },
            async getHistory () {
              const variables = {
                currencyId: null,
                networkId: null,
                query: this.search,
                settingId: this.settingId
              }
              this.history = await this.getCollectingHistory(variables)
            },
        }
    }
</script>

<template>
<div class="profits">
    <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :background-transparent="false"
        @open-create-collection-modal="openCreateModal"
    />
    <container v-else>
        <div class="profits__topBar">
            <div class="profits__action">
                <base-button :label="$t('profits.create-setting')" :disabled="!isActionsAllowedShow" @click="openCreateModal" >
                    <template #prepend>
                        <svg class="button-icon" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 0C4.27614 0 4.5 0.223858 4.5 0.5V3.5H7.5C7.77614 3.5 8 3.72386 8 4C8 4.27614 7.77614 4.5 7.5 4.5H4.5V7.5C4.5 7.77614 4.27614 8 4 8C3.72386 8 3.5 7.77614 3.5 7.5V4.5H0.5C0.223858 4.5 0 4.27614 0 4C0 3.72386 0.223858 3.5 0.5 3.5H3.5V0.5C3.5 0.223858 3.72386 0 4 0Z" fill="white"/>
                        </svg>
                    </template>
                </base-button>
            </div>
            <div class="profits__gridRow">
                <business-wallet-network-select
                    :value="currencyValue"
                    :list="filteredCurrencies"
                    :label="$t('business-wallet.currency')"
                    :placeholder="currencySelectPlaceholder"
                    uniq-key="id"
                    clearable
                    @input="handleSelectCurrency"
                />
                <business-wallet-network-select
                    :value="networksValue"
                    :list="filteredNetworks"
                    :label="$t('orders.network')"
                    :placeholder="networkSelectPlaceholder"
                    uniq-key="id"
                    clearable
                    @input="handleSelectNetwork"
                />
                <history-button :disabled="!isHistoryAllowedShow" @click="goToHistory" />
                <downloader
                    v-model="selectedDownloadType"
                    class="file-downloader"
                    type="secondary"
                >
                    <template #icon>
                        <svg
                            id="btn-select-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                                fill="#744DB2"
                            />
                            <path
                                d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                                fill="#744DB2"
                            />
                        </svg>
                    </template>
                </downloader>
            </div>
        </div>
        <div class="profits__topBar--mobile">
            <base-button :disabled="!isActionsAllowedShow" :label="$t('profits.create-setting')" @click="openCreateModal" ><
                <template #prepend>
                    <svg class="button-icon" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 0C4.27614 0 4.5 0.223858 4.5 0.5V3.5H7.5C7.77614 3.5 8 3.72386 8 4C8 4.27614 7.77614 4.5 7.5 4.5H4.5V7.5C4.5 7.77614 4.27614 8 4 8C3.72386 8 3.5 7.77614 3.5 7.5V4.5H0.5C0.223858 4.5 0 4.27614 0 4C0 3.72386 0.223858 3.5 0.5 3.5H3.5V0.5C3.5 0.223858 3.72386 0 4 0Z" fill="white"/>
                    </svg>
                </template>
            </base-button>
            <div class="profits__topBar--mobile-right">
                <history-button :disabled="!isHistoryAllowedShow" @click="goToHistory" />
                <downloader
                    v-model="selectedDownloadType"
                    class="file-downloader"
                    type="secondary"
                >
                    <template #icon>
                        <svg
                            id="btn-select-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                                fill="#744DB2"
                            />
                            <path
                                d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                                fill="#744DB2"
                            />
                        </svg>
                    </template>
                </downloader>
                <filter-mobile
                  :is-filtered="!isEmptyFilters"
                  :close-outside="true"
                  @confirm="loadSettings"
                >
                    <div class="profits__topBar--filter">
                        <business-wallet-network-select
                            :value="networksValue"
                            :list="filteredNetworks"
                            :label="$t('orders.network')"
                            :placeholder="networkSelectPlaceholder"
                            clearable
                            uniq-key="id"
                            @input="handleSelectNetwork"
                        />
                        <business-wallet-network-select
                            :value="currencyValue"
                            :list="filteredCurrencies"
                            :label="$t('business-wallet.currency')"
                            :placeholder="currencySelectPlaceholder"
                            uniq-key="id"
                            clearable
                            @input="handleSelectCurrency"
                        />
                    </div>
                </filter-mobile>
            </div>
        </div>
        <template v-if="!isLoading">
            <template v-if="settingsList.length">
                <div class="profits__list">
                    <list-item v-for="item in settingsList" :item="item" />
                </div>
                <div class="profits__list--mobile">
                    <list-mobile-item v-for="item in settingsList" :item="item" />
                </div>
            </template>
            <div class="profits__empty" v-else>
                <div class="profits__empty-title">{{ $t('profits.empty-settings-title') }}</div>
                <div class="profits__empty-subtitle">{{ $t('profits.empty-settings-subtitle') }}</div>
            </div>
        </template>
        <new-spinner v-else />
    </container>
    <CreateSettingsModal />
    <UpdateSettingsModal/>
    <ChangeAddressBook/>
    <NewAddressModal/>
    <DeleteSettingModal />
    <TwoFaConfirmation :modal-name="'CreateSettingsModal'" />
    <TwoFaRestriction />
</div>
</template>

<style scoped lang="scss">
.profits {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;
    &__empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        color: var(--new-front-secondary-font-color);
        &-title {
            font-size: $h2;
        }
        &-subtitle {
            font-size: $h4;
        }
    }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p30;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }
    &__list {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 1800px) {
            overflow: auto;
          min-height: 200px;
        }
        @media (max-width: 780px) {
            display: none;
        }
        &--mobile {
            display: none;
            @media (max-width: 780px) {
                display: flex;
                align-items: center;
                width: 100%;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
  &__subtitle {
    font-size: $h2;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    margin-bottom: $p30;
    margin-left: $p5;
  }
    &__gridRow {
        display: grid;
        grid-template-columns: 1fr 1fr 50px 50px;
        grid-gap: 10px;
        align-items: center;
    }
    &__action {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
    }
}
.profits__topBar {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
    &--mobile {
        display: none;
    }
    &--filter {
        min-width: 280px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
::v-deep {
    .base-btn {
        width: 180px;
        @media (max-width: 500px) {
            width: 160px;
        }
    }
    .file-downloader {
        max-width: 48px !important;
        .base-btn {
            width: 48px !important;
        }
    }
    .filter {
        .base-btn {
            width: 100% !important;
        }
    }
    .new-order-modal__footer {
        .base-btn {
            width: 100% !important;
        }
    }
}
::v-deep .icon  {
    .currency-network-icon__network {
        width: 22px !important;
        height: 22px !important;

        border: none !important;
        position: unset !important;

        top: unset !important;
        left: unset !important;

        transform: unset !important;
    }
}
@media (max-width: 780px) {
    .profits__topBar {
        display: none;
        &--mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $p10;
            margin-bottom: $p20;
            &-right {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
    ::v-deep {
        .container-new {
            padding: $p20;
        }
    }
}
@media (max-width: 576px) {
    .profits {
      padding: 0 10px 10px 10px;
    }
    .profits__title {
        font-size: $h3;
        margin-top: $p10;
        margin-bottom: $p10;
        font-weight: 500;
    }
    .profits__subtitle {
        font-size: $h6;
        margin-bottom: $p10;
    }
    //::v-deep .container-new {
    //    padding-bottom: 30px;
    //}
}
::v-deep {
    .advanced-balances-select-wrapper .sublabel {
        display: none;
    }
    .advanced-balances-select-wrapper .label {
        display: none;
    }
}
</style>
