<script >
    // import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";

    import Container from "@/public/components/common/uiKit/Container.vue";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
    import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import ListItem from "@/public/components/elements/collect-profits/ListItem.vue";
    import CreateSettingsModal from "@/public/components/modals/collecting-profits/CreateSettingsModal.vue";
    import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
    import Downloader from "@/public/components/elements/personal-addresses/Select/Downloader/index.vue";
    import ListMobileItem from "@/public/components/elements/collect-profits/ListMobileItem.vue";
    import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
    import HistoryButton from "@/public/components/common/uiKit/history/index.vue";
    import UpdateSettingsModal from "@/public/components/modals/collecting-profits/UpdateSettingsModal.vue";
    import Table from "@/public/components/common/uiKit/table/Table.vue";
    import CollectingHistoryItem from "@/public/components/elements/collect-profits/HistoryItem.vue";
    import CollectingHistoryMobileItem from "@/public/components/elements/collect-profits/HistoryMobileItem.vue";
    import { isAlbertCrypto } from "@/config";

    export default {
        name: "CollectingHistory",
        rulesAlias: "collecting",
        components: {
            CollectingHistoryMobileItem,
            CollectingHistoryItem,
            UpdateSettingsModal,
            HistoryButton,
            FilterMobile,
            ListMobileItem,
            NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
            Downloader,
            TwoFaConfirmation,
            CreateSettingsModal,
            ListItem, BaseButton, RefreshButton, OrphanSearch, BusinessWalletNetworkSelect, Container, Table
        },
        data () {
            return {
                networksValue: null,
                currencyValue: null,
                selectedDownloadType: undefined,
                search: '',
                sortValue: null,
            }
        },
        computed: {
            ...mapState('app', ['networks', 'currencies', 'isLoading']),
            ...mapState('collectingProfits', ['settingsHistory']),
            ...mapGetters('app', [
                'getSelectedOrganization'
            ]),
            ...mapGetters('organizations', ['userRoleRules']),
            title () {
                // if (this.settingId) {
                //     return this.$t('profits.history-individual-title')
                // }
              return this.$t('profits.history-title')
            },
            tableHeader () {
                return [
                    {
                        title: this.$t('profits.alias'),
                        slug: 'settingAlias',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.wallet'),
                        slug: 'addressFromType',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.save'),
                        slug: 'savedFeeAmount',
                        isSort: true
                    },
                    {
                        title: this.$t('profits.date'),
                        slug: 'createdAt',
                        isSort: true
                    },
                    {
                        title: this.$t('profits.amount'),
                        slug: 'amount',
                        isSort: true
                    },
                    {
                        title: this.$t('profits.amount-fee-setup'),
                        slug: 'settingAmount',
                        isSort: true
                    },
                    {
                        title: this.$t('profits.currency-network'),
                        slug: 'currency',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.address-from'),
                        slug: 'addressFrom',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.address-to'),
                        slug: 'addressTo',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.transaction'),
                        slug: 'txId',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.status'),
                        slug: 'status',
                        isSort: false
                    },
                    {
                        title: this.$t('profits.transactions-collected'),
                        slug: 'transactionsAmount',
                        isSort: false
                    },
                ]
            },
            settingId () {
                if (!this.$route.params.id) {
                    return null
                }
              return this.$route.params.id
            },
            sortedHistory() {
                let arr = this.settingsHistory
                if (!this.sortValue) {
                    return arr
                }
                if (this.sortValue.slug === 'createdAt') {
                    arr = this.sortValue.direction ? arr.toSorted((a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))) : arr.toSorted((a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)))
                    return arr
                }
                arr = this.sortValue.direction ? arr.toSorted((a, b) => a[this.sortValue.slug] - b[this.sortValue.slug]) : arr.toSorted((a, b) =>  b[this.sortValue.slug] -  a[this.sortValue.slug])
                return arr
            },
            filteredCurrencies () {
                const {
                    currencies,
                } = this;
                let sortedCurrencies = currencies.filter(({isFiat}) => !isFiat);

                if (this.networksValue) {
                    sortedCurrencies = sortedCurrencies.filter((i) => {
                        return i.networks.find(({id}) => id === this.networksValue.id);
                    });
                    return sortedCurrencies;
                }

                return sortedCurrencies;
            },
            filteredNetworks() {
                const {
                    currencies,
                } = this;

              const networks = {};

              for(const currency of currencies) {
                if(!currency.allowBusiness) {
                  continue;
                }

                for(const network of currency.networks) {
                  if(!network.allowDeposit) {
                    continue;
                  }

                  networks[network.id] = network;
                }
              }

              return Object.values(networks);
            },
            currencySelectPlaceholder () {
                return this.$t('orders.select_currencies')
            },
            networkSelectPlaceholder () {
                return this.$t('orders.select_network')
            },
            isRequestProcessed () {
                return this.setting.id
            }
        },
        created() {
            const isAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_history;
            if (!isAllowed) {
              this.$Message.error(this.$t('rules.not_allowed'));
              if ( isAlbertCrypto ) {
                this.$router.push('/merchant/business-wallet');
              } else {
                this.$router.push('/merchant/payments');
              }
              return
            }
          this.loadSettings()
        },
        watch: {
            search() {
                this.loadSettings()
            },
            selectedDownloadType(v) {
                if (!!v) {
                    const payload = JSON.stringify({
                        'organizationId': this.getSelectedOrganization.id,
                        'networkId': this.networksValue?.id ? this.networksValue?.id : null,
                        'currencyId': this.currencyValue?.id ? this.currencyValue?.id : null,
                        'settingId': this.settingId,
                        'query': this.search
                    })

                    window.open(`/downloads/${v.id}-collecting-history?_=` + payload)
                    this.selectedDownloadType = undefined
                }
            }
        },
        methods: {
            ...mapActions('collectingProfits', ['getCollectingHistory']),
            ...mapActions('app', [
                'openModal',
            ]),
            sortTable (slug, direction) {
                this.sortValue = {
                    slug,
                    direction
                }
            },
            openCreateModal () {
              this.openModal({
                  name: 'CreateSettingsModal'
              })
            },
            handleSelectNetwork(value) {
                this.networksValue = value
                this.currencyValue = null;
                this.loadSettings()
            },
            async loadSettings () {
                const variables = {
                    currencyId: this.currencyValue ? this.currencyValue.id : null,
                    networkId: this.networksValue ? this.networksValue.id : null,
                    query: this.search,
                    settingId: this.settingId
                }
                await this.getCollectingHistory(variables)
            },
            handleSelectCurrency(value) {
                this.currencyValue = value;
                this.loadSettings()
            },
            catchPagination(page) {
                this.page = page;
            },
            refresh () {
                this.currencyValue = null;
                this.networksValue = null;
                this.search = '';
                this.loadSettings()
            }
        }
    }
</script>

<template>
<div class="profits">
<!--    <h1 class="profits__title">-->
<!--        {{ title }}-->
<!--    </h1>-->
    <router-link class="profits__return" to="/collecting-profits">
        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
        </svg>
        {{ $t('orphan.return') }}
    </router-link>
<!--    <h3 class="profits__subtitle">-->
<!--        {{ $t('orphan.subtitle') }}-->
<!--    </h3>-->
    <container>
        <div class="profits__topBar">
            <div class="profits__action">
                <orphan-search v-model="search" :placeholder="$t('profits.hash')" />
                <refresh-button @click="loadSettings"/>
            </div>
            <div class="profits__gridRow">
                <business-wallet-network-select
                    :value="currencyValue"
                    :list="filteredCurrencies"
                    :label="$t('business-wallet.currency')"
                    :placeholder="currencySelectPlaceholder"
                    uniq-key="id"
                    clearable
                    @input="handleSelectCurrency"
                />
                <business-wallet-network-select
                    :value="networksValue"
                    :list="filteredNetworks"
                    :label="$t('orders.network')"
                    :placeholder="networkSelectPlaceholder"
                    uniq-key="id"
                    clearable
                    @input="handleSelectNetwork"
                />
                <downloader
                    v-model="selectedDownloadType"
                    class="file-downloader"
                    type="secondary"
                >
                    <template #icon>
                        <svg
                            id="btn-select-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                                fill="#744DB2"
                            />
                            <path
                                d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                                fill="#744DB2"
                            />
                        </svg>
                    </template>
                </downloader>
            </div>
        </div>
        <div class="profits__topBar--mobile">
            <div class="profits__topBar--mobile-right">
                <refresh-button @click="loadSettings"/>
                <downloader
                    v-model="selectedDownloadType"
                    class="file-downloader"
                    type="secondary"
                >
                    <template #icon>
                        <svg
                            id="btn-select-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                                fill="#744DB2"
                            />
                            <path
                                d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                                fill="#744DB2"
                            />
                        </svg>
                    </template>
                </downloader>
                <filter-mobile :close-outside="true" @confirm="loadSettings">
                    <div class="profits__topBar--filter">
                        <orphan-search v-model="search" :placeholder="$t('profits.hash')" />
                        <business-wallet-network-select
                            :value="networksValue"
                            :list="filteredNetworks"
                            :label="$t('orders.network')"
                            :placeholder="networkSelectPlaceholder"
                            uniq-key="id"
                            clearable
                            @input="handleSelectNetwork"
                        />
                        <business-wallet-network-select
                            :value="currencyValue"
                            :list="filteredCurrencies"
                            :label="$t('business-wallet.currency')"
                            :placeholder="currencySelectPlaceholder"
                            uniq-key="id"
                            clearable
                            @input="handleSelectCurrency"
                        />
                    </div>
                </filter-mobile>
            </div>
        </div>
            <div :class="['profits__table']">
                <Table v-if="!isLoading" :class="[!settingsHistory.length ? 'profits__table--empty' : '' ]" :header-list="tableHeader" @sort="sortTable">
                    <template v-if="!isLoading">
                        <template v-if="settingsHistory.length">
                            <collecting-history-item v-for="item in sortedHistory" :item="item" />
                        </template>
                        <div class="profits__empty" v-else>
                            <div class="profits__empty-title">{{ $t('profits.empty-history-title') }}</div>
                            <div class="profits__empty-subtitle">{{ $t('profits.empty-history-subtitle') }}</div>
                        </div>
                    </template>
                </Table>
            </div>
            <div v-if="!isLoading" class="profits__mobileList">
                <collecting-history-mobile-item v-for="item in settingsHistory" :item="item" />
            </div>
        <new-spinner v-else/>

    </container>
</div>
</template>

<style scoped lang="scss">
.profits {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;
    &__return {
        //margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--new-front-bg-btn-primary-bg-modal-primary);
        font-size: $h4;
        font-weight: 500;
        margin-bottom: $p20;
    }
    &__mobileList {
        display: none;
    }
    &__empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        color: var(--new-front-secondary-font-color);
        &-title {
            font-size: $h2;
        }
        &-subtitle {
            font-size: $h4;
        }
    }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p30;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }
    &__list {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 1600px) {
            overflow: auto;
        }
        @media (max-width: 780px) {
            display: none;
        }
        &--mobile {
            display: none;
            @media (max-width: 780px) {
                display: flex;
                align-items: center;
                width: 100%;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
  &__subtitle {
    font-size: $h2;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    margin-bottom: $p30;
    margin-left: $p5;
  }
    &__gridRow {
        display: grid;
        grid-template-columns: 1fr 1fr 50px;
        grid-gap: 10px;
        align-items: center;
    }
    &__action {
        display: grid;
        grid-template-columns: 1fr 50px;
        align-items: center;
        width: 100%;
        gap: 10px;
        ::v-deep {
            .currency-search-wrapper {
                width: 100%;
            }
        }
    }
}
.profits__topBar {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
    &--mobile {
        display: none;
    }
    &--filter {
        min-width: 280px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
::v-deep {
    .base-btn {
        width: 180px ;
        @media (max-width: 500px) {
            width: 160px ;
        }
    }
    .file-downloader {
        max-width: 48px !important;
        width: 48px !important;
        .base-btn {
            max-width: 48px !important;
            width: 48px !important;
        }
    }
    .filter {
        .base-btn {
            width: 100% !important;
        }
    }
}
.profits__table--empty {
    overflow: hidden !important;
    .table {
        overflow: hidden;
    }
}
::v-deep .icon  {
    .currency-network-icon__network {
        width: 22px !important;
        height: 22px !important;

        border: none !important;
        position: unset !important;

        top: unset !important;
        left: unset !important;

        transform: unset !important;
    }
}
@media (max-width: 1024px) {
   .profits__table {
       display: none;
   }
    .profits__mobileList {
        display: block;
    }
}
@media (max-width: 780px) {
    .profits__return {
        padding-left: 10px;
    }
    .profits__topBar {
        display: none;
        &--mobile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: $p10;
            margin-bottom: $p20;
            &-right {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
    ::v-deep {
        .container-new {
            padding: $p20;
        }
    }
}
@media (max-width: 576px) {
    .profits {
      padding: 0 10px 10px 10px;
    }
    .profits__title {
        font-size: $h3;
        margin-top: $p10;
        margin-bottom: $p10;
        font-weight: 500;
    }
    .profits__subtitle {
        font-size: $h6;
        margin-bottom: $p10;
    }
    //::v-deep .container-new {
    //    padding-bottom: 30px;
    //}
}
::v-deep {
    .advanced-balances-select-wrapper .sublabel {
        display: none;
    }
    .advanced-balances-select-wrapper .label {
        display: none;
    }
}
::v-deep {
    .table {
        width: 100%;
        overflow: auto;
    }
    .profits__table--empty {
        .table {
            overflow: hidden;
        }
    }
    .header {
        grid-template-columns:  repeat(12, 17%);
        width: 100%;
        min-height: unset;
        grid-gap: 0;

    }
    .header__item {
        min-height: 56px;
        background: var(--new-front-select-item-bg-active);
        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding-right: 30px;
        }
    }
}
</style>
