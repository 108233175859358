<template>
    <div class="address">
        <Container class="address__container">
            <preloader v-if="isLoading || !isInit"/>
            <zero-state
              v-else-if="showZeroState"
              :meta="zeroStateMeta"
              @open-create-address-modal="openNewAddressModal"
            />
            <template v-else>
                <div class="address__table">
                  <HeaderTable @search="handleSearch" :has-selected-network="hasSelectedNetwork" @has-selected-network="hasSelectedNetwork = $event"/>
                  <TableColumns class="mobileHidden" />
                  <TableLine v-for="item in filteredList" :item="item"/>
                  <p v-if="!filteredList.length" class="empty-addresses">{{ $t('addresses.no_data_available') }}</p>
                </div>
                <pagination
                  size="small"
                  :total="count"
                  :page-size="10"
                  :current="currentPage"
                  @page-change="changePage" v-if="count && filteredList.length"
                />
            </template>
        </Container>
        <RemoveAddressBook/>
        <NewAddressModal :limit="10"/>
        <ChangeAddressBook/>
    </div>
</template>

<script>
import RemoveAddressBook from '/public/components/modals/addressBook/RemoveAddressBook';
import NewAddressModal from '/public/components/modals/addressBook/NewAddressModal';
import ChangeAddressBook from '/public/components/modals/addressBook/ChangeAddressBook';
import HeaderTable from '../components/elements/addressBook/HeaderTable';
import TableLine from '../components/elements/addressBook/TableLine';
import pagination from "@/public/components/common/uiKit/Pagination.vue";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import CommonInfoModal from "../components/modals/commonInfo";
import Container from "@/public/components/common/uiKit/Container.vue";
import TableColumns from "@/public/components/elements/addressBook/TableColumns.vue";
import EmptyTable from "@/public/components/elements/EmptyTable.vue";
import { isAlbertCrypto } from "@/config";

export default {
    name: 'addressBook',
    title: 'addressBook',
    rulesAlias: 'address_book',
    components: {
      EmptyTable,
      TableColumns,
      Container,
      CommonInfoModal,
      HeaderTable,
      TableLine,
      pagination,
      Preloader,
      RemoveAddressBook,
      NewAddressModal,
      ChangeAddressBook,
      ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data () {
      return {
          search: '',
          hasSelectedNetwork: false
      }
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_address_book;
        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    mounted() {
        const {
            $route,
            $router,
            isInit,
            getAddressBook,
            networkAddressBook,
        } = this;

        if (isInit) {
            getAddressBook({
              route: $route,
              router: $router,
              page: this.currentPage,
              networkId: this.networkFromUrl,
              showUrlFilters: false,
              limit: 10
            });

            networkAddressBook();
        }
    },

    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading'
        ]),
        ...mapState('addressBook', ['pages', 'count', 'pagesCount']),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('addressBook', {
            listAddress: state => state.listCurrenciesAddress,
        }),
        networkFromUrl({ $route }) {
            return $route.query.network
        },
        currentPage() {
            const pageFromUrl = this.$route.query.page;

            if (!pageFromUrl) {
                return 1;
            }

            return Number(pageFromUrl);
        },
        filteredList () {
            return this.listAddress.filter((item) => item.address.includes(this.search))
        },
        showZeroState({ search, hasSelectedNetwork, filteredList }) {
            return !search && !hasSelectedNetwork && !filteredList.length
        },
        zeroStateMeta() {
            return {
                title: this.$t('zero-states.address-book.title'),
                subtitle: this.$t('zero-states.address-book.subtitle'),
                notifications: [
                  this.$t('zero-states.address-book.notifications.first-line')
                ],
                actions: [
                  {
                    title: this.$t('zero-states.address-book.actions.create-address.title'),
                    info: this.$t('zero-states.address-book.actions.create-address.info'),
                    btnName: this.$t('zero-states.address-book.actions.create-address.btn-name'),
                    action: 'open-create-address-modal'
                  },
                  {
                    title: this.$t('zero-states.address-book.actions.api-integration.title'),
                    info: this.$t('zero-states.address-book.actions.api-integration.info'),
                    btnName: this.$t('zero-states.address-book.actions.api-integration.btn-name'),
                    btnSecondary: true,
                    withoutIcon: true,
                    apiMethod: '/api-reference/address-book/post_add'
                  }
                ],
                type: 'default'
            }
        }
    },

    methods: {
        ...mapActions('addressBook', [
            'getAddressBook',
            'networkAddressBook',
        ]),
        ...mapActions('app', [
          'openModal',
        ]),
        openNewAddressModal() {
          const {openModal} = this;

          openModal({
            name: 'NewAddressModal',
          });
        },
        handleSearch (value) {
          this.search = value

          const url = new URL(window.location.href)
          if (value) {
            url.searchParams.set('search', value)
          } else {
            url.searchParams.delete('search')
          }
          window.history.pushState(null, '', url.toString())
        },
        changePage(pageNumber) {
            const {
              $route,
              $router,
            } = this;

            $router.replace({
              query: {
                ...$route.query,
                page: pageNumber
              }
            })
        },
    },
};
</script>

<style scoped lang="scss">
.empty-addresses {
  width: 100%;
  font-size: $h2;
  font-weight: 500;
  color: var(--new-front-secondary-font-color);

  padding-left: $p30;
  padding-right: $p30;
  padding-top: $p30;

  text-align: center;
}
.address {
  font-family: var(--new-front-font-family);

  padding: 0 30px 30px 30px;

  @media (max-width: 576px) {
    padding: 0 10px 10px 10px;
  }
  &__container {
    width: 100%;
    margin-top: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    margin-bottom: 13px;
    margin-left: 10px;

    @media (min-width: 376px) {
      margin-left: 0;
    }
  }

  &__sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: $h2;
    line-height: 23px;
    color: var(--new-front-secondary-font-color);
    margin-bottom: 20px;

    @media (min-width: 376px) {
      margin-left: 0;
    }
  }
}
@media (max-width: 860px) {
  .mobileHidden {
    display: none;
  }
  .address__title {
    font-size: $h3;
  }
}
</style>
