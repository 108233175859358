<template>
  <div>

    <div class="api-keys">

<!--      <h1 class="api-keys__title">-->
<!--        {{ $t('api_keys.title') }}-->
<!--      </h1>-->
      <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :background-transparent="false"
        @open-create-api-keys-modal="openCreateNewApiKeysModal"
      />
      <div v-else>
        <Container v-show="!modalName || modalName === 'RemoveApiKeysModal'">
          <template v-if="!isLoading">
            <ApiKeysTopBar
                class="api-keys__top-bar"
                @searchApiKeys="setSearchText"
                @openCreateModal="openCreateNewApiKeysModal"
            />
            <ApiKeysListHeader class="api-keys__list-header"/>
            <template v-if="hasApiKeys">
              <PreviewApiKeys
                  v-for="(apiKeysItem, key) in sortedApiKeys"
                  :key="key"
                  class="api-keys__preview"
                  :data="apiKeysItem"
                  @editApiKey="editApiKey"
                  @deleteKey="deleteApiKey"
              />
            </template>
            <div v-else class="empty">
              {{ $t('orphan.empty') }}
            </div>
          </template>

          <preloader v-if="isLoading || !isInit"/>
        </Container>
      </div>
      <api-keys-create v-if="modalName === 'CreateNewApiKeysModal'" @return="closeModal" />
      <api-keys-edit v-if="modalName === 'EditApiKeyModal'" :data="editApiKeyData" @return="closeModal" />
      <RemoveApiKeysModal/>
      <TwoFaRestriction />
      <TwoFaConfirmation  :modal-name="modalName" @closeModal="closeModal" />
    </div>

  </div>
</template>

<script>

  import {mapState, mapActions, mapGetters} from 'vuex';

import ApiKeysTopBar from "/public/components/elements/api-keys/ApiKeysTopBar";
import ApiKeysListHeader from "/public/components/elements/api-keys/ApiKeysListHeader";
import PreviewApiKeys from "/public/components/elements/api-keys/PreviewApiKeys";
import EmptyTable from "/public/components/elements/EmptyTable";
import CreateNewApiKeysModal from "/public/components/modals/api-keys/CreateNewApiKeysModal";
import RemoveApiKeysModal from "/public/components/modals/api-keys/RemoveApiKeysModal";
import EditKeyModal from "../../components/modals/api-keys/EditApiKeyModal";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import TwoFaRestriction from "../../components/modals/twoFactorAuthorization/TwoFaRestriction";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";
import Container from "@/public/components/common/uiKit/Container.vue";
import ApiKeysCreate from "@/public/pages/api-keys/api-keys-create.vue";
import ApiKeysEdit from "@/public/pages/api-keys/api-keys-edit.vue";
import OrdersTopBar from "@/public/components/elements/orders/OrdersTopBar.vue";
import {isAlbertCrypto} from "@/config";

export default {
    name: 'ApiKeysPage',
    title: 'api_keys.title',
    rulesAlias: 'api_keys',
    components: {
      ApiKeysEdit,
      ApiKeysCreate,
      OrdersTopBar,
      Container,
      TwoFaConfirmation,
      TwoFaRestriction,
      Preloader,
      ApiKeysTopBar,
      ApiKeysListHeader,
      PreviewApiKeys,
      EmptyTable,
      CreateNewApiKeysModal,
      RemoveApiKeysModal,
      EditKeyModal,
      ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },
    data() {
        return {
          searchText: '',
          modalName: '',
          openedModal: '',
          editApiKeyData: {},
        };
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isViewAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_api_keys;

        if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    computed: {
        ...mapState('user', [
            'apiKeys',
        ]),
        ...mapState('app', ['isInit', 'isLoading']),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('user', {
          userOtpEnabled: state => state.user.otpToken,
        }),
        showZeroState({ searchText, hasApiKeys, modalName }) {
          return !searchText && !hasApiKeys && modalName !== 'CreateNewApiKeysModal'
        },
        sortedApiKeys() {
            const {
                apiKeys,
                searchText,
            } = this;

            if (!searchText) {
                return apiKeys;
            }

            return apiKeys.filter(apiKey => {
                return apiKey.alias.includes(searchText);
            });
        },
        hasApiKeys() {
            return Boolean(this.sortedApiKeys.length);
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.api-keys.title'),
            subtitle: this.$t('zero-states.api-keys.subtitle'),
            notifications: [
              this.$t('zero-states.api-keys.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.api-keys.actions.create-keys.title'),
                info: this.$t('zero-states.api-keys.actions.create-keys.info'),
                btnName: this.$t('zero-states.api-keys.actions.create-keys.btn-name'),
                action: 'open-create-api-keys-modal'
              }
            ],
            type: 'default'
          }
        }
    },
    beforeMount() {
        this.fetchApiKeys();
    },
    methods: {
        ...mapActions('user', [
            'fetchApiKeys',
            'fetchApiRulesMap'
        ]),
        ...mapActions('app', [
            'openModal',
        ]),
        deleteApiKey({ id, alias }) {
            if (!this.userOtpEnabled) {
                this.openModal({
                    name: 'TwoFaRestriction',
                });
                return;
            }
            this.modalName = 'RemoveApiKeysModal'
            const {
                openModal,
            } = this;
            this.openModal({
                name: 'RemoveApiKeysModal',
                payload: {
                    id,
                    alias,
                },
            });
        },
      editApiKey (data) {
        if (!this.userOtpEnabled) {
          this.openModal({
            name: 'TwoFaRestriction',
          });
          return;
        }
          this.editApiKeyData = data
          this.modalName = 'EditApiKeyModal'
      },
      openCreateNewApiKeysModal() {
        if (!this.userOtpEnabled) {
          this.openModal({
            name: 'TwoFaRestriction',
          });
          return;
        }
        this.modalName = 'CreateNewApiKeysModal'
      },
      closeModal () {
       this.modalName = ''
      },
      setSearchText(searchText) {
          this.searchText = searchText;
      },
    },
};

</script>

<style lang="scss" scoped>

.api-keys {
  padding: 0 30px 30px 30px;
  @media screen and (max-width: 950px) {
    padding: 0 10px 10px 10px;
  }
  &__preview {
    &:last-of-type {
      &::v-deep {
        .apiKeys-collapse__header {
          border-bottom: none;
        }
      }
    }
  }
  ::v-deep .at-input__original {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    background: var(--secondary-color);
    color: var(--primary-font-color);
    border: 1px solid var(--border-color);

    &::placeholder {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }
  }
  &__title {
    font-family: $Gilroy;
    font-size: $h1;
    color: var(--new-front-primary-font-color);
    font-weight: 400;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (max-width: 950px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &__sub {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: $h2;
    line-height: 23px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 30px;
  }

  &__paragraph {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: $h2;
    line-height: 23px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 20px;
  }

  &__top-bar {
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
  }

  &__list-header {
    display: grid;
    grid-template-columns: repeat(3,1fr) 150px;
    grid-auto-flow: column;
    grid-gap: 5px;
    align-items: center;
    padding: 0 0 0 30px;
    min-height: 56px;
    background: var(--new-front-select-item-bg-active);
    border-radius: 10px;
  }

  &__list {
    border-bottom: 20px;
    background: var(--secondary-color);
    padding-bottom: 35px;
    border-radius: 20px;
    box-shadow: var(--shadow-container);
    margin-top: var(--margin-container-top);
    border: 1px solid var(--border-color);

    &_empty {
      padding-bottom: 5px;
    }
  }

  &__button-mobile {
    display: none;
  }
}
.empty {
  font-weight: 500;
  padding: 30px;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  color: var(--new-front-text-primary-bg-modal-primary);
}
@media screen and (max-width: 950px) {
  .empty {
    font-size: 16px;
    border-top: 1px solid var(--new-front-divider-color)
  }
  .api-keys {
    &__paragraph {
      padding-left: 10px;
    }

    &__title {
      padding-left: 10px;
    }

    &__list-header {
      display: none;
    }

    &__top-bar {
      padding: 20px;
      justify-content: flex-end;
      min-height: 61px;
      margin-bottom: 0;
    }

    &__button-mobile {
      width: 100%;
      max-width: 300px;
      border-radius: 12px;
      background: #0059F7;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px auto 20px;
      transition: all 0.3s ease-in;

      &:hover {
        background: var(--blue-400);
      }

      ::v-deep .at-btn__icon {
        font-size: 24px;
      }
    }
  }

}
</style>
