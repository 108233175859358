<!--<template>-->
<!--    <div v-if="pending">-->
<!--        проверяем ссыль-->
<!--    </div>-->
<!--    <div v-else>-->
<!--        <div v-if="success">-->
<!--            ссыль верная, иди на <router-link :to="{name: 'dashboard'}">главную</router-link>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--            ссыль не верная, попробуй еще раз-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<script>
import axios from "axios";
import { isAlbertCrypto } from "@/config";

export default {
    name: "sign-up-confirm",
    data() {
        return {
            pending: true,

            success: false,
        };
    },

    mounted() {
        this.checkLink();
    },
  watch: {
    success (value) {
      // if (value) {
      //   this.$router.push({name: 'dashboard'});
      // }
    }
  },
    methods: {
        async checkLink() {
            const linkId = this.$route.params.linkId;

            try {
                const {data} = await axios.post('/public-api/sign-up/confirm/' + linkId);

                if(!data.success) {
                    /*
                    INCORRECT_CONFIRM_LINK: 3003,
                    CONFIRMATION_LINK_NOT_FOUND: 4001,
                    * */

                    switch (data.code) {
                        case 3003:{
                            alert('Некорректная ссыль');
                        }break;
                        case 4001:{
                            alert('Неверная ссыль');
                        }break;
                        default:
                            alert('Internal error, code: ' + data.code);
                            break;
                    }

                    this.success = false;
                } else {
                    // this.$store.commit('app/setAuthLogin', true);
                    // this.$store.commit('app/setAuthTwoFa', true);
                    // this.$store.dispatch('app/init', null);

                    this.success = true;

                    window.location.href = isAlbertCrypto ? '/business-wallet' : '/payments';
                }

                this.pending = false;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this.checkLink();
            }
        }
    },
}
</script>

<style scoped>

</style>
