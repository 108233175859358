<template>
  <div>
    <div class="transactions">
      <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :background-transparent="false"
      />
      <Container v-else class="transactions__container">
        <TransactionsTopBar
          :page="currentPage"
          :sortValue="sortValue"
          @is-empty-filters="isEmptyFilters = $event"
          @changePage="changePage"
          class="transactions__top-bar"
        />
        <template v-if="hasTransactions">
          <div :class="['transactions__list', hasTransactions ? '' : 'transactions__list_empty']">
<!--            <TransactionsListHeader class="transactions__list-header"/>-->
            <table-header class="headerTable" @sort="sortTable" :list="formattedHeaderList" :key="lang"/>
            <template v-if="!isLoading">
                <PreviewTransaction
                  v-for="(transaction, key) in mappedList"
                  :key="key"
                  :data="transaction"
                  @openTransaction="openTransaction"
                />
            </template>
            <preloader v-if="isLoading || !isInit"/>
          </div>
          <div class="transactions__pagination">
            <pagination
                :size="'small'"
                :total="totalTransactionsCount"
                :page-size="transactionsCountOnPage"
                :current="currentPage"
                v-if="totalTransactionsCount && !isLoading"
                @page-change="changePage"
            />
          </div>
        </template>
          <div v-else class="transactions__table-empty">
              {{ $t('orphan.empty') }}
          </div>
      </Container>
    </div>
      <transition name="slide-fade">
          <transaction-info v-if="isInfoOpened" />
      </transition>
  </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex';

import TransactionsTopBar from "/public/components/elements/transactions/TransactionsTopBar";
import TransactionsListHeader from "/public/components/elements/transactions/TransactionsListHeader";
import PreviewTransaction from "/public/components/elements/transactions/PreviewTransaction";
import AtPagination from "at-ui/src/components/pagination";
import EmptyTable from "/public/components/elements/EmptyTable";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import Pagination from "@/public/components/common/uiKit/Pagination.vue";

import CommonInfoModal from "/public/components/modals/commonInfo";
import Container from "@/public/components/common/uiKit/Container.vue";
import TableHeader from "@/public/components/common/uiKit/table/TableHeader.vue";
import TransactionInfo from "@/public/components/elements/transactions/TransactionInfo.vue";
import InvoicesTopBar from "@/public/components/elements/bills/InvoicesTopBar.vue";
import OrdersTopBar from "@/public/components/elements/orders/OrdersTopBar.vue";
import { isAlbertCrypto } from "@/config";
export default {
    name: 'TransactionsPage',
    title: 'transactions.title',
    rulesAlias: 'transactions',
    components: {
      OrdersTopBar,
      InvoicesTopBar,
      TransactionInfo,
      TableHeader,
      Container,
      Preloader,
      TransactionsTopBar,
      TransactionsListHeader,
      PreviewTransaction,
      AtPagination,
      EmptyTable,
      Pagination,
      CommonInfoModal,
      ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')

    },
    data() {
      return {
          sortValue: '',
          currentPage: 1,
          isEmptyFilters: false
      }
    },
    created() {
        const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
        const isShowAllowed = this.userRoleRules[this.$options.rulesAlias].entities.view_transactions

        if (!isAllowed || !isShowAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          if ( isAlbertCrypto ) {
            this.$router.push('/merchant/business-wallet');
          } else {
            this.$router.push('/merchant/payments');
          }
        }
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
        ]),
        ...mapState('transactions', [
            'transactionsCountOnPage',
            'transactions',
            'totalTransactionsCount',
            'isInfoOpened',
            'columns',
            'defaultColumns'
        ]),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('user', ['lang']),
        ...mapState('addressBook', {
          addressBook: state => state.listCurrenciesAddress,
        }),
        // currentPage() {
        //     const pageFromUrl = this.$route.query.page;
        //
        //     if (!pageFromUrl) {
        //         return 1;
        //     }
        //
        //     return Number(pageFromUrl);
        // },

        mappedList () {
          return this.transactions.map((item) => ({
            ...item,
            addressBookAlias: this.addressBook.find(el => item.addressFrom === el.address)?.alias || '',
            addressBookToAlias: this.addressBook.find(el => item.addressTo === el.address)?.alias || ''
          }))
        },

        hasTransactions() {
            return Boolean(this.transactions.length);
        },
        headerList () {
            return [
                {
                    title: this.$t('transactions.date_of_creation'),
                    slug: 'date_of_creation',
                    isSort: true
                },
                {
                    title: this.$t('transactions.type'),
                    slug: 'type',
                    isSort: false
                },
                {
                    title: this.$t('transactions.basis'),
                    slug: 'basis',
                    isSort: false
                },
                {
                    title: this.$t('transactions.source'),
                    slug: 'source',
                    isSort: false
                },
                {
                    title: this.$t('transactions.method'),
                    slug: 'method',
                    isSort: false
                },
                {
                    title: this.$t('advance_balances.history.table.cols.currency-and-network'),
                    slug: 'CurrencyNetwork',
                    isSort: false
                },
                {
                    title: this.$t('transactions.address_from'),
                    slug: 'address_from',
                    isSort: false
                },
                {
                    title: this.$t('transactions.address_to'),
                    slug: 'address_to',
                    isSort: false
                },
                {
                    title: 'TX_hash',
                    slug: 'tx_hash',
                    isSort: false
                },
                {
                    title: this.$t('transactions.status'),
                    slug: 'status',
                    isSort: false
                },
                {
                    title: `${this.$t('transactions.sum.debit')}/${this.$t('transactions.sum.credit')}`,
                    slug: 'debitCredit',
                    isSort: false
                },

            ]
        },
        formattedHeaderList () {
            const list = []
            this.columns.forEach(item => {
                const headerTitle = this.headerList.find(({slug}) => slug === item.title)
                if (headerTitle && item.active) {
                    list.push(headerTitle)
                }
            })
            return list
        },
        showZeroState({ isEmptyFilters, hasTransactions }) {
          return isEmptyFilters && !hasTransactions
        },
        zeroStateMeta() {
          return {
            title: this.$t('zero-states.transactions.title'),
            subtitle: this.$t('zero-states.transactions.subtitle'),
            notifications: [
              this.$t('zero-states.transactions.notifications.first-line')
            ],
            actions: [
              {
                title: this.$t('zero-states.transactions.actions.advanced-balance-replenishment.title'),
                info: this.$t('zero-states.transactions.actions.advanced-balance-replenishment.info'),
                btnName: this.$t('zero-states.transactions.actions.advanced-balance-replenishment.btn-name'),
                route: { name: 'advanced-balances' },
                withoutIcon: true
              },
              {
                title: this.$t('zero-states.transactions.actions.create-invoice.title'),
                info: this.$t('zero-states.transactions.actions.create-invoice.info'),
                btnName: this.$t('zero-states.transactions.actions.create-invoice.btn-name'),
                route: { name: 'invoices' },
                withoutIcon: true
              },
              {
                title: this.$t('zero-states.transactions.actions.create-order.title'),
                info: this.$t('zero-states.transactions.actions.create-order.info'),
                btnName: this.$t('zero-states.transactions.actions.create-order.btn-name'),
                route: { name: 'orders' },
                withoutIcon: true
              },
              {
                title: this.$t('zero-states.transactions.actions.create-subscription.title'),
                info: this.$t('zero-states.transactions.actions.create-subscription.info'),
                btnName: this.$t('zero-states.transactions.actions.create-subscription.btn-name'),
                route: { name: 'subscriptions' },
                withoutIcon: true
              }
            ],
            type: 'default'
          }
        }
    },
    async mounted() {
        const {
            $route,
            isInit,
            fetchTransactions,
            getColumns,
            defaultColumns,
          getAddressBook,
        } = this;

        if (isInit) {
            await fetchTransactions({
                route: $route,
                showUrlFilters: false,
                page: this.currentPage
            });
          getAddressBook({
            route: $route,
            showUrlFilters: false,
            page: this.currentPage,
            limit: 9999
          });
            await getColumns()
            if (!this.columns.length) {
                await this.sendColumns(defaultColumns)
            }
        }
    },
    methods: {
        ...mapActions('transactions', [
            'fetchTransactions',
            'fetchTransaction',
            'sendColumns',
            'getColumns',
        ]),
        ...mapActions('addressBook', [
          'getAddressBook',
        ]),

        sortTable (slug, direction) {
            this.sortValue = direction ? "ASC" : "DESC"
        },
        async openTransaction (id) {
            const {
                fetchTransaction,
            } = this;

            await fetchTransaction({
                id,
            })
        },
        changePage(pageNumber) {
            this.currentPage = pageNumber
        },
    },
    watch: {
        isInit() {
            const {
                $route,
                fetchTransactions,
            } = this;

            fetchTransactions({
                route: $route,
                showUrlFilters: false,
                page: this.currentPage
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.transactions {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;

  &__table-empty {
    font-weight: 500;
    padding: 30px;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    color: var(--new-front-text-primary-bg-modal-primary);
  }
  //&__container {
  //  max-width: 1415px;
  //  background: var(--secondary-color);
  //  border-radius: 20px;
  //  box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
  //  border: 1px solid var(--border-color);
  //  margin-top: var(--margin-container-top);
  //  margin-bottom: var(--margin-container-bottom);
  //}

  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: 10px;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }

  &__paragraph-block {
    display: flex;
    align-items: start;
  }

  &__paragraph {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-h3);
    line-height: var(--line-height);
    color: var(--primary-font-color);
  }

  &__top-bar {
    //margin-bottom: 10px;
  }

  &__list {
    //padding-top: 20px;
    overflow-x: scroll;
    padding-bottom: 20px;
    //min-width: 790px;
    &_empty {
      padding-bottom: 0;
    }
  }

  &__pagination {
    min-height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 20px 20px;

    @media screen and (max-width: 400px) {
      &::v-deep .at-pagination {
        gap: 8px;
      }
    }
  }

  &__empty {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 1330px) {
  .transactions {

    //padding-right: 20px;
    //padding-left: 20px;

    &__title {
      //padding-left: 20px;

    }

    &__paragraph {
      //padding-left: 20px;
    }
  }


}

@media screen and (max-width: 1180px) {
  .transactions {
    &__title {

    }
  }

}

@media screen and (max-width: 950px) {
  .transactions {
    padding: 10px;
    &__title {
      padding-left: 10px;
      padding-right: 10px;
    }
    &__paragraph {
      padding-left: 10px;
      padding-right: 10px;
    }
    .headerTable {
      display: none;
    }
  }
  .transactions__top-bar {
    padding-top: 20px;
  }
}

@media screen and (max-width: 375px) {
  .transactions {
    padding-left: 0;
    padding-right: 0;
  }

}
::v-deep {
  .header {
    //background: transparent;
    grid-template-columns: repeat(11, 25%);
    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(11, 25%);
    }
    padding: 0;
    //padding-left: 40px;
    gap: 0;
    &__item {
      background: var(--new-front-select-item-bg-active);
      min-height: 56px;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 40px;
      }
      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.flip-list-move {
  transition: transform 1s;
}
</style>
